import * as React from "react";
import styled from "styled-components";
import { DesktopId } from "../../../app/desktops";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ModalContext, RenderModal } from "../../../app/modals";
import { modalsSelector, popModal } from "../../../store/modals/modals-slice";
import { DesktopLayoutContext } from "../desktop-layouts/base-desktop-layout";

type StyledSiteModalContainerProps = {
  $isVisible: boolean;
};

const StyledSiteModalContainer = styled.div<StyledSiteModalContainerProps>`
  pointer-events: ${(props) => (props.$isVisible ? "all" : "none")};
  z-index: 999;
  position: fixed;
  inset: 0;
`;

const StyledModalBackdrop = styled.div.attrs<{
  $isActive: boolean;
  $zIndex: number;
}>((props) => ({
  inert: props.$isActive ? "" : undefined,
  style: {
    zIndex: props.$zIndex,
  },
}))<{
  $isActive: boolean;
  $zIndex: number;
}>`
  background-color: ${(props) => (props.$isActive ? "#2229" : "#2220")};
  transition: background-color ${(props) => props.theme.timings.guiFadeSeconds}s
    ease;
  position: fixed;
  inset: 0;
`;

const StyledModalPositioner = styled.div.attrs<{ $zIndex: number }>(
  (props) => ({
    style: {
      zIndex: props.$zIndex,
    },
  })
)<{ $zIndex: number }>`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

export default function SiteModalContainer() {
  const { openModals } = useAppSelector(modalsSelector);
  const dispatch = useAppDispatch();
  const hasModalOpen = openModals.length > 0;

  React.useEffect(() => {
    const cb = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        dispatch(popModal());
      }
    };
    document.addEventListener("keydown", cb);
    return () => document.removeEventListener("keydown", cb);
  });

  return (
    // Mock implementation of DesktopLayoutContext, since all modals are sticky and centered
    <DesktopLayoutContext.Provider
      value={{
        desktopId: DesktopId.MODALS_CONTAINER,
        canWindowsRearrange: false,
        isBoundsCheckRelativeToViewport: false,
        // TODO: implement this
        desktopWidth: 0,
        desktopHeight: 0,
      }}
    >
      <StyledSiteModalContainer
        aria-hidden={!hasModalOpen}
        $isVisible={hasModalOpen}
      >
        {openModals.map((modalConfig, index) => {
          const isActive = index === openModals.length - 1;
          return (
            <StyledModalPositioner key={modalConfig.id} $zIndex={index + 1}>
              <ModalContext.Provider value={{ isActive }}>
                <RenderModal config={modalConfig} />
              </ModalContext.Provider>
            </StyledModalPositioner>
          );
        })}
        <StyledModalBackdrop
          $isActive={hasModalOpen}
          $zIndex={openModals.length - 1}
        />
      </StyledSiteModalContainer>
    </DesktopLayoutContext.Provider>
  );
}
