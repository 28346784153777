exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apps-spotify-screenshot-generator-index-tsx": () => import("./../../../src/pages/apps/spotify-screenshot-generator/index.tsx" /* webpackChunkName: "component---src-pages-apps-spotify-screenshot-generator-index-tsx" */),
  "component---src-pages-apps-spotify-screenshot-generator-terms-tsx": () => import("./../../../src/pages/apps/spotify-screenshot-generator/terms.tsx" /* webpackChunkName: "component---src-pages-apps-spotify-screenshot-generator-terms-tsx" */),
  "component---src-pages-apps-subler-chapter-text-generator-index-tsx": () => import("./../../../src/pages/apps/subler-chapter-text-generator/index.tsx" /* webpackChunkName: "component---src-pages-apps-subler-chapter-text-generator-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-index-tsx": () => import("./../../../src/pages/posts/index.tsx" /* webpackChunkName: "component---src-pages-posts-index-tsx" */),
  "component---src-pages-posts-msdos-compilation-tsx": () => import("./../../../src/pages/posts/msdos-compilation.tsx" /* webpackChunkName: "component---src-pages-posts-msdos-compilation-tsx" */),
  "component---src-pages-posts-text-shadow-is-awful-in-chrome-edge-tsx": () => import("./../../../src/pages/posts/text-shadow-is-awful-in-chrome-edge.tsx" /* webpackChunkName: "component---src-pages-posts-text-shadow-is-awful-in-chrome-edge-tsx" */),
  "component---src-pages-print-resume-tsx": () => import("./../../../src/pages/print/resume.tsx" /* webpackChunkName: "component---src-pages-print-resume-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */)
}

