import React from "react";

export enum ProgramTitlebarPosition {
  TOP = "programwindow-grid-top",
  LEFT = "programwindow-grid-left",
}

export type ProgramTitlebarButtonConfig = {
  id: string;
  alt: string;
  label: React.ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
};
