import React from "react";
import styled from "styled-components";
import { Button, ButtonProps } from "../atoms/button";

interface ToggleButtonMenuProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  button: Omit<ButtonProps, "onClick">;
  menu: React.ReactNode;
}

let idCounter = 0;

export function ToggleButtonMenu(props: ToggleButtonMenuProps) {
  const { isOpen, toggleIsOpen, button, menu } = props;
  const menuIdRef = React.useRef("");
  React.useEffect(() => {
    menuIdRef.current = `ToggleButtonMenu-${idCounter++}`;
  }, []);

  const rootRef = React.useRef<HTMLDivElement>(null);

  const onButtonClick = React.useCallback(() => {
    toggleIsOpen();
  }, [toggleIsOpen]);

  React.useEffect(() => {
    const cb = (e: MouseEvent) => {
      if (isOpen && !rootRef.current?.contains(e.target as Element)) {
        toggleIsOpen();
      }
    };
    document.addEventListener("click", cb);
    return () => document.removeEventListener("click", cb);
  }, [rootRef, isOpen, toggleIsOpen]);
  return (
    <StyledToggleButtonMenuContainer ref={rootRef} $isOpen={isOpen}>
      <StyledToggleButton
        {...button}
        onClick={onButtonClick}
        aria-expanded={isOpen}
        aria-owns={menuIdRef.current}
      />
      <StyledMenuWrapper
        $isOpen={isOpen}
        aria-hidden={!isOpen}
        id={menuIdRef.current}
      >
        <StyledMenuAnimator $isOpen={isOpen}>{menu}</StyledMenuAnimator>
      </StyledMenuWrapper>
    </StyledToggleButtonMenuContainer>
  );
}

const StyledToggleButtonMenuContainer = styled.div<{
  $isOpen: boolean;
}>`
  position: relative;
  display: inline-block;
`;

const StyledToggleButton = styled(Button)`
  width: 100%;
  height: 100%;
`;

const StyledMenuWrapper = styled.div.attrs<{
  $isOpen: boolean;
}>((props) => ({
  inert: props.$isOpen ? undefined : "",
}))<{
  $isOpen: boolean;
}>`
  pointer-events: ${(props) => (props.$isOpen ? "all" : "none")};
  position: absolute;
  top: 100%;
  clip-path: inset(0 -100px -100px -100px);
`;

const StyledMenuAnimator = styled.div<{
  $isOpen: boolean;
}>`
  transform: ${(props) => (props.$isOpen ? "none" : "translateY(-20px)")};
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  /* TODO: ability to customize this shadow */
  box-shadow: ${(props) => props.theme.shadows.soft.medium};

  transition: transform
      ${(props) =>
        props.theme.timings.guiFadeSeconds * (props.$isOpen ? 1 : 0.5)}s
      ease,
    opacity
      ${(props) =>
        props.theme.timings.guiFadeSeconds * (props.$isOpen ? 1 : 0.5)}s
      ease;
`;
