import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ColorThemeName } from "../../../store/theme-config/site-theme-types";
import {
  setColorThemeName,
  themeConfigSelector,
} from "../../../store/theme-config/theme-config-slice";
import { SiteModal } from "../../layout/modals/site-modal";

export type ModalSiteCustomizeOptions = {};

export function ModalSiteCustomize(props: {
  options: ModalSiteCustomizeOptions;
}) {
  const { options } = props;
  const dispatch = useAppDispatch();
  const themeConfig = useAppSelector(themeConfigSelector);

  return (
    <SiteModal title="Customize Site">
      {/* TODO: atoms for form elements (esp. to restore spacing?) */}
      <fieldset
        style={{
          margin: "inherit",
          padding: "inherit",
          paddingTop: 0,
        }}
      >
        <legend
          style={{ margin: "inherit", padding: "inherit", paddingTop: 0 }}
        >
          Colors
        </legend>
        {Object.values(ColorThemeName).map((colorThemeName) => (
          <label key={colorThemeName} style={{ display: "block" }}>
            <input
              type="radio"
              checked={themeConfig.colorThemeName === colorThemeName}
              onChange={() => dispatch(setColorThemeName(colorThemeName))}
              style={{ margin: "inherit" }}
            />{" "}
            {colorThemeName}
          </label>
        ))}
      </fieldset>
    </SiteModal>
  );
}

// TODO: TEMPORARY
export function ModalTest(props: { options: { num: number } }) {
  return (
    <SiteModal title={"Test " + props.options.num}>
      Test {props.options.num}
    </SiteModal>
  );
}
