export enum ThemeFontFamily {
  TITILLIUM_WEB = "Titillium Web, sans-serif",
  SOURCE_CODE_PRO = "Source Code Pro, monospace",
}

export enum ThemeColorHue {
  WHITE = -1,
  BLACK = -2,
  BLUE = 224,
  PURPLE = 245,
  ORANGE = 22,
}

export enum ThemeColorLuminosity {
  BASE = "base",
  LIGHTER = "lighter",
  LIGHTER_2 = "lighter2",
  DARKER = "darker",
}

export type ThemeColorLuminosityType = `${ThemeColorLuminosity}`;

export type ThemeColorAlpha = 0 | 25 | 50 | 75 | 90 | 100;

export type ThemeColorMutationProps = {
  luminosity?: ThemeColorLuminosityType;
  alpha?: ThemeColorAlpha;
};

export enum ColorThemeName {
  LIGHT = "Light",
  DARK = "Dark",
}

export type ColorTheme = {
  text: {
    base: ThemeColor;
    codeBackground: ThemeColor;
    link: ThemeColor;
  };
  gui: {
    controls: {
      background: ThemeColor;
      foreground: ThemeColor;
    };
    printPageBackground: ThemeColor;
    startMenuAppsButton: ThemeColor;
    titlebarBarBackgroundStart: ThemeColor;
    titlebarBarBackgroundEnd: ThemeColor;
    titlebarBarLabel: ThemeColor;
    titlebarButtonBackground: ThemeColor;
    titlebarButtonBorder: ThemeColor;
    titlebarButtonLabel: ThemeColor;
    toolbarBackground: ThemeColor;
    windowBorder: ThemeColor;
    windowBackground: ThemeColor;
  };
  palette: {
    foreground: ThemeColor;
    background: ThemeColor;
    white: ThemeColor;
    black: ThemeColor;
    blue: ThemeColor;
    purple: ThemeColor;
    orange: ThemeColor;
  };
};

export interface ThemeColor {
  (): string;
  toString(): string;
  withChange(props: ThemeColorMutationProps): ThemeColor;
  alpha(alpha: ThemeColorAlpha): ThemeColor;
  lighter: ThemeColor;
  darker: ThemeColor;
}
