import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch } from "../../../app/hooks";
import { setIsMainMenuOpen } from "../../../store/taskbar/taskbar-slice";
import { ThemeColor } from "../../../store/theme-config/site-theme-types";
import {
  SiteTransitionLink,
  SiteTransitionLinkProps,
} from "../../layout/transitions/site-transition-link";

export const enum LinePosition {
  NONE,
  LEFT,
  BOTTOM,
}

export interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  colorTheme?: ThemeColor;
  colorBackground?: ThemeColor;
  colorForeground?: ThemeColor;
  disabled?: boolean;
  faIcon?: FontAwesomeIconProps;
  linePosition?: LinePosition;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

function ButtonInner(props: ButtonProps, ref: React.Ref<HTMLButtonElement>) {
  const theme = useTheme();
  const {
    children,
    className,
    colorTheme = theme.colors.palette.foreground,
    colorBackground = theme.colors.gui.controls.background,
    colorForeground = theme.colors.gui.controls.foreground,
    disabled = false,
    faIcon: faIconProps = null,
    linePosition = LinePosition.NONE,
    onClick,
  } = props;
  return (
    <StyledButton
      ref={ref}
      className={className}
      $colorTheme={colorTheme}
      $colorBackground={colorBackground}
      $colorForeground={colorForeground}
      $linePosition={linePosition}
      onClick={onClick}
      disabled={disabled}
    >
      {faIconProps && (
        <span style={{ marginRight: "0.4em" }}>
          <FontAwesomeIcon {...faIconProps} />
        </span>
      )}
      {children}
    </StyledButton>
  );
}

export const Button = React.forwardRef(ButtonInner);

interface StyledButtonProps {
  $colorTheme: ThemeColor;
  $colorBackground: ThemeColor;
  $colorForeground: ThemeColor;
  $linePosition: LinePosition;
}

const StyledButton = styled.button<StyledButtonProps>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: ${(props) => props.theme.spacings.buttons.paddingVertical}px
    ${(props) => props.theme.spacings.buttons.paddingHorizontal}px
    ${(props) => props.theme.spacings.buttons.paddingVertical}px;
  background-color: ${(props) => props.$colorBackground};
  display: flex;

  color: ${(props) =>
    props.disabled ? props.$colorForeground.alpha(75) : props.$colorForeground};
  font-family: ${(props) => props.theme.fonts.family.gui};
  font-size: 1em;
  text-decoration: none;

  border: 0;
  :hover {
    ${(props) => {
      if (props.disabled) {
        return {};
      }
      const { paddingHorizontal, paddingVertical } =
        props.theme.spacings.buttons;
      switch (props.$linePosition) {
        case LinePosition.NONE:
          return {};
        case LinePosition.LEFT:
          return {
            paddingLeft: paddingHorizontal - 2,
            borderLeft: `2px solid ${props.$colorTheme.toString()}`,
          };
        case LinePosition.BOTTOM:
          return {
            paddingBottom: paddingVertical - 2,
            borderBottom: `2px solid ${props.$colorTheme.toString()}`,
          };
      }
    }}
  }

  :hover {
    ${(props) =>
      !props.disabled &&
      `
      background-color: ${props.$colorBackground.lighter};
      border-bottom-color: ${props.$colorTheme.lighter};
    `}
  }

  :active {
    ${(props) =>
      !props.disabled &&
      `
      background-color: ${props.$colorBackground.darker};
      border-bottom-color: ${props.$colorTheme.darker};
    `}
  }

  /* TODO: make standard background-color transition across everything? So that changing site colors doesn't look out of sync? */
  /* transition: background-color ${(props) =>
    props.theme.timings.guiFadeSeconds}s
    ease; */
`;

interface LinkButtonProps
  extends Pick<
    ButtonProps,
    | "className"
    | "colorTheme"
    | "colorBackground"
    | "colorForeground"
    | "faIcon"
  > {
  children: React.ReactNode;
  linePosition?: LinePosition;
  link: Omit<SiteTransitionLinkProps, "children">;
}

export function LinkButton(props: LinkButtonProps) {
  const theme = useTheme();
  const {
    children,
    className,
    colorTheme = theme.colors.palette.foreground,
    colorBackground = theme.colors.gui.controls.background,
    colorForeground = theme.colors.gui.controls.foreground,
    linePosition = LinePosition.NONE,
    link: linkProps,
    faIcon: faIconProps,
  } = props;
  const dispatch = useAppDispatch();
  const onClick = React.useCallback(() => {
    dispatch(setIsMainMenuOpen(false));
  }, [dispatch, setIsMainMenuOpen]);
  return (
    <StyledButton
      as={SiteTransitionLink}
      {...linkProps}
      className={className}
      $colorTheme={colorTheme}
      $colorBackground={colorBackground}
      $colorForeground={colorForeground}
      $linePosition={linePosition}
      onClick={onClick}
    >
      {faIconProps && (
        <span style={{ marginRight: "0.4em" }}>
          <FontAwesomeIcon {...faIconProps} />
        </span>
      )}
      {children}
    </StyledButton>
  );
}
