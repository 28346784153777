import { DefaultTheme, ThemedStyledProps } from "styled-components";
import { ThemeColor, ColorTheme, ColorThemeName } from "./site-theme-types";
import {
  ThemeColorAlpha,
  ThemeColorHue,
  ThemeColorLuminosity,
  ThemeColorLuminosityType,
  ThemeColorMutationProps,
} from "./site-theme-types";

type ThemeColorProps = {
  h: ThemeColorHue;
  s: number;
  l?: ThemeColorLuminosityType;
  a?: ThemeColorAlpha;
  luminosityMap?: { [K in ThemeColorLuminosityType]?: number };
};

export interface ThemeColorImpl {
  (): string;
  toString(): string;
}

class ExtensibleFunction extends Function {
  // @ts-ignore Skip calling super()
  constructor(f) {
    return Object.setPrototypeOf(f, new.target.prototype);
  }
}

/*
Instances of ThemeColorImpl can be called as a function to be converted to CSS `hsla()` string - this allows it to be used in Styled-Components.
https://stackoverflow.com/a/36871498
*/
export class ThemeColorImpl extends ExtensibleFunction implements ThemeColor {
  h: ThemeColorHue;
  s: number;
  l: number;
  a: ThemeColorAlpha;
  luminosityMap: { [K in ThemeColorLuminosityType]: number };
  paramL: ThemeColorLuminosityType;

  constructor(props: ThemeColorProps) {
    super((props: ThemedStyledProps<{}, DefaultTheme>) => this.__call__(props));

    this.luminosityMap = Object.assign(
      {
        [ThemeColorLuminosity.DARKER]: 61,
        [ThemeColorLuminosity.BASE]: 63,
        [ThemeColorLuminosity.LIGHTER]: 75,
        [ThemeColorLuminosity.LIGHTER_2]: 95,
      },
      props.luminosityMap
    );

    this.paramL = props.l || "base";

    this.h = props.h;
    this.s = props.s;
    this.l = this.luminosityMap[this.paramL];
    this.a = props.a ?? 100;
  }

  __call__(_props: ThemedStyledProps<{}, DefaultTheme>) {
    return this.toString();
  }

  toString() {
    let { h, s, l, a } = this;
    if (h === ThemeColorHue.WHITE || h === ThemeColorHue.BLACK) {
      h = 0;
    }
    return `hsla(${h}, ${s}%, ${l}%, ${a / 100})`;
  }

  withChange(props: ThemeColorMutationProps): ThemeColor {
    return new ThemeColorImpl({
      h: this.h,
      s: this.s,
      l: props.luminosity ?? this.paramL,
      a: props.alpha ?? this.a,
      luminosityMap: this.luminosityMap,
    });
  }

  get darker() {
    let newLuminosity: ThemeColorLuminosityType;
    switch (this.paramL) {
      case "darker":
        newLuminosity = "darker"; // TODO: handle this case?
        break;
      case "base":
        newLuminosity = "darker";
        break;
      case "lighter":
        newLuminosity = "base";
        break;
      case "lighter2":
        newLuminosity = "lighter";
        break;
    }
    return this.withChange({ luminosity: newLuminosity });
  }

  get lighter() {
    let newLuminosity: ThemeColorLuminosityType;
    switch (this.paramL) {
      case "darker":
        newLuminosity = "base";
        break;
      case "base":
        newLuminosity = "lighter";
        break;
      case "lighter":
        newLuminosity = "lighter2";
        break;
      case "lighter2":
        newLuminosity = "lighter2"; // TODO: handle this case?
        break;
    }
    return this.withChange({ luminosity: newLuminosity });
  }

  alpha(a: ThemeColorAlpha) {
    return this.withChange({ alpha: a });
  }
}

const palette: Omit<
  DefaultTheme["colors"]["palette"],
  "foreground" | "background"
> = {
  white: new ThemeColorImpl({
    h: ThemeColorHue.WHITE,
    s: 0,
    luminosityMap: {
      darker: 80,
      base: 85,
      lighter: 93,
      lighter2: 100,
    },
  }),
  black: new ThemeColorImpl({
    h: ThemeColorHue.BLACK,
    s: 0,
    luminosityMap: {
      darker: 8,
      base: 22,
      lighter: 33,
      lighter2: 45,
    },
  }),
  blue: new ThemeColorImpl({
    h: ThemeColorHue.BLUE,
    s: 65,
    luminosityMap: {
      darker: 52,
    },
  }),
  purple: new ThemeColorImpl({
    h: ThemeColorHue.PURPLE,
    s: 65,
  }),
  orange: new ThemeColorImpl({
    h: ThemeColorHue.ORANGE,
    s: 65,
  }),
};

function defineBasicColorTheme(
  FOREGROUND: ThemeColor,
  BACKGROUND: ThemeColor
): ColorTheme {
  return {
    text: {
      base: FOREGROUND,
      codeBackground: BACKGROUND.darker,
      link: FOREGROUND.darker,
    },
    gui: {
      controls: {
        background: BACKGROUND,
        foreground: FOREGROUND,
      },

      printPageBackground: BACKGROUND,

      startMenuAppsButton: BACKGROUND.lighter,

      titlebarBarBackgroundStart: BACKGROUND.lighter,
      titlebarBarBackgroundEnd: BACKGROUND.darker,
      titlebarBarLabel: FOREGROUND,

      titlebarButtonBackground: BACKGROUND.lighter,
      titlebarButtonBorder: BACKGROUND.lighter,
      titlebarButtonLabel: FOREGROUND,

      toolbarBackground: BACKGROUND,

      windowBorder: BACKGROUND.darker,
      windowBackground: BACKGROUND.lighter,
    },
    palette: {
      foreground: FOREGROUND,
      background: BACKGROUND,
      ...palette,
    },
  };
}

const ColorThemeLight: ColorTheme = defineBasicColorTheme(
  palette.black,
  palette.white
);

let ColorThemeDark: ColorTheme = defineBasicColorTheme(
  palette.white,
  palette.black
);
ColorThemeDark.gui.titlebarBarLabel = palette.white.lighter;
ColorThemeDark.gui.titlebarButtonLabel = palette.white.lighter;
ColorThemeDark.gui.windowBackground = palette.black;

export const ColorThemes: { [K in ColorThemeName]: ColorTheme } = {
  [ColorThemeName.LIGHT]: ColorThemeLight,
  [ColorThemeName.DARK]: ColorThemeDark,
};
