import React from "react";
import styled from "styled-components";

const StyledWallpaperSvg = styled.svg.attrs({
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1 1",
  preserveAspectRatio: "xMidYMid meet",
})`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-image: radial-gradient(peachpuff, peachpuff, pink);
`;

export const SiteWallpaper = () => {
  return <StyledWallpaperSvg></StyledWallpaperSvg>;
};
