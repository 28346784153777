import { PageProps } from "gatsby";
import * as React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import "wicg-inert";
import { useAppSelector } from "../../app/hooks";
import store, { persistor } from "../../app/store";
import { selectCurrentTheme } from "../../store/theme-config/theme-config-slice";
import { SiteDesktopContainer } from "./desktop-layouts/site-desktop-container";
import SiteModalContainer from "./modals/site-modal-container";
import SiteTaskbar from "./taskbar/site-taskbar";
import { IsTransitionLinkAvailableContext } from "./transitions/transition-link-context";
import { SiteWallpaper } from "./wallpapers/site-wallpaper";

/**
 * Global wrapper that remains mounted across page transitions (gatsby-link/gatsby-plugin-transition-link).
 * This mounts all of the persistent elements on the site, leaving only the page-specific content to be defined under src/pages.
 * This also instantiates the global Redux store, since we want global state to persist across page transitions (e.g. taskbar recents)
 */
export const SitewideLayout = ({
  element,
  props,
}: {
  element: React.ReactNode;
  props: PageProps;
}): JSX.Element => {
  // TODO: remove initGoogleAnalyticsPageviewFix after merging https://github.com/gatsbyjs/gatsby/pull/37017/files
  React.useEffect(() => {
    const interval = setInterval(() => {
      // @ts-expect-error
      if (window.gtag) {
        const pageviewDelay = 1250;
        initGoogleAnalyticsPageviewFix(pageviewDelay);
        clearInterval(interval);
      }
    }, 1000);
    setTimeout(() => clearInterval(interval), 10000);
    return () => clearInterval(interval);
  }, []);
  const usePrintLayout = props.path?.startsWith("/print/");
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppThemeProvider>
          <GlobalStyles $isPrintLayout={usePrintLayout} />
          <div>
            {!usePrintLayout && <SiteWallpaper />}
            {!usePrintLayout && <SiteTaskbar />}
            {/* gatsby-transition-link layout wrapper is mounted here */}
            <IsTransitionLinkAvailableContext.Provider value={true}>
              {usePrintLayout ? (
                element
              ) : (
                <SiteDesktopContainer>{element}</SiteDesktopContainer>
              )}
            </IsTransitionLinkAvailableContext.Provider>
            <IsTransitionLinkAvailableContext.Provider value={false}>
              <SiteModalContainer />
            </IsTransitionLinkAvailableContext.Provider>
          </div>
        </AppThemeProvider>
      </PersistGate>
    </Provider>
  );
};

const GlobalStyles = createGlobalStyle<{ $isPrintLayout: boolean }>`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  min-height: 100vh;
  background-color: ${(props) =>
    props.$isPrintLayout
      ? props.theme.colors.gui.printPageBackground
      : props.theme.colors.gui.toolbarBackground};
  color: ${(props) => props.theme.colors.text.base};
}
body, button {
  // Chalkboard is to catch any unspecified fonts on the site
  font-family: ${(props) =>
    process.env.NODE_ENV === "development"
      ? "Chalkboard"
      : props.theme.fonts.family.body};
}
/* Fix gatsby-plugin-transition-link messing with me */
.tl-edges {
  overflow: inherit !important;
  width: 100%;
}
.tl-wrapper + .tl-wrapper {
  margin-left: 0% !important;
  margin-right: 0;
}
`;

const AppThemeProvider = (props: { children: React.ReactNode }) => {
  const theme = useAppSelector(selectCurrentTheme);
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

let originalGtag: any = null;
function initGoogleAnalyticsPageviewFix(pageviewDelay: number): void {
  try {
    if (originalGtag !== null) {
      return;
    }
    // @ts-ignore
    originalGtag = window.gtag;
    // @ts-ignore
    window.gtag = (...args) => {
      let isPageviewEvent = false;
      try {
        isPageviewEvent = args[0] === "event" && args[1] === "page_view";
      } catch (_e) {}
      if (isPageviewEvent) {
        setTimeout(() => {
          // Taken from gatsby-plugin-google-gtag
          const newPagePath = location
            ? location.pathname + location.search + location.hash
            : undefined;
          originalGtag("event", "page_view", {
            page_path: newPagePath,
          });
        }, pageviewDelay);
      } else {
        originalGtag(...args);
      }
    };
  } catch (e) {}
}
