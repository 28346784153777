import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export enum ResumeLayoutMode {
  AUTO = "AUTO",
  PRINT = "PRINT",
  WEB = "WEB",
}

export enum ResumeColorMode {
  COLORFUL = "COLORFUL",
  GRAYSCALE = "GRAYSCALE",
}

export interface ResumeState {
  resumeLayoutMode: ResumeLayoutMode;
  resumeColorMode: ResumeColorMode;
  showModalAboutMe: boolean;
}

const initialState: ResumeState = {
  resumeLayoutMode: ResumeLayoutMode.AUTO,
  resumeColorMode: ResumeColorMode.COLORFUL,
  showModalAboutMe: false,
};

export const ResumeSlice = createSlice({
  name: "resumeConfig",
  initialState,
  reducers: {
    setResumeLayoutMode: (
      state,
      { payload }: PayloadAction<ResumeLayoutMode>
    ) => {
      if (payload in ResumeLayoutMode) {
        state.resumeLayoutMode = payload;
      }
    },
    setResumeColorMode: (
      state,
      { payload }: PayloadAction<ResumeColorMode>
    ) => {
      if (payload in ResumeColorMode) {
        state.resumeColorMode = payload;
      }
    },
    setShowModalAboutMe: (state, { payload }: PayloadAction<boolean>) => {
      state.showModalAboutMe = payload;
    },
  },
});

export const { setResumeLayoutMode, setResumeColorMode, setShowModalAboutMe } =
  ResumeSlice.actions;
export const resumeConfigSelector = (state: RootState) => state.resumeConfig;
export const resumeConfigReducer = ResumeSlice.reducer;
