import * as React from "react";
import {
  ModalSiteCustomize,
  ModalTest,
} from "../components/site-settings/modal-customize/modal-site-customize";
import { ModalAppsSctgAbout } from "../components/subler-chapter-text-generator/templates/sctg-about-modal";
import { ModalAppsSctgRemoveChapters } from "../components/subler-chapter-text-generator/templates/sctg-remove-chapters-modal";
import { ModalConfigWithId, ModalType } from "../store/modals/modals-slice";

// All site-wide modals to be mapped here.
// TODO: clean up desktop slice windowStates when a window is closed
export function RenderModal({
  config,
}: {
  config: ModalConfigWithId;
}): JSX.Element {
  switch (config.type) {
    case ModalType.APPS_SCTG_ABOUT:
      return <ModalAppsSctgAbout options={config.options} />;
    case ModalType.APPS_SCTG_REMOVE_CHAPTERS:
      return <ModalAppsSctgRemoveChapters options={config.options} />;
    case ModalType.SITE_CUSTOMIZE:
      return <ModalSiteCustomize options={config.options} />;
    case ModalType.TEST_MODAL:
      return <ModalTest options={config.options} />;
  }
}

export const ModalContext = React.createContext({
  isActive: false,
});
