import React from "react";

interface DragListenerProps {
  children: React.ReactNode;
  className?: string;
  onDragStatusChange: (isDragging: boolean) => void;
  onDrag: ({ dx, dy }: { dx: number; dy: number }) => void;
}

interface ActiveTouch {
  id: number;
  previousX: number;
  previousY: number;
}

export function DragListenerBox(props: DragListenerProps) {
  const ref = React.useRef<HTMLDivElement>(null);

  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const [isDragging, setIsDragging] = React.useState(false);
  // const [dragStartX, setDragStartX] = React.useState(0);
  // const [dragStartY, setDragStartY] = React.useState(0);

  const [activeTouches, setActiveTouches] = React.useState<ActiveTouch[]>([]);

  const onElementMouseDown = React.useCallback<React.MouseEventHandler>(
    (e) => {
      if (e.button === 0) {
        setIsDragging(true);
        e.preventDefault();
      }
    },
    [setIsDragging]
  );

  const onElementTouchStart = React.useCallback(
    (e: TouchEvent) => {
      e.preventDefault();
      setIsDragging(true);
      setActiveTouches([
        ...activeTouches,
        ...Array.from(e.changedTouches).map((touch) => ({
          id: touch.identifier,
          previousX: touch.screenX,
          previousY: touch.screenY,
        })),
      ]);
    },
    [setIsDragging, setActiveTouches, activeTouches]
  );

  const onElementTouchMove = React.useCallback(
    (e: TouchEvent) => {
      e.preventDefault();
      Array.from(e.targetTouches).forEach((touch) => {
        const tracked = activeTouches.find((at) => at.id === touch.identifier);
        if (tracked) {
          const dx = touch.screenX - tracked.previousX;
          const dy = touch.screenY - tracked.previousY;
          tracked.previousX = touch.screenX;
          tracked.previousY = touch.screenY;
          props.onDrag({
            dx,
            dy,
          });
        }
      });
    },
    [activeTouches, props.onDrag]
  );

  React.useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      element.addEventListener("touchstart", onElementTouchStart, {
        passive: false,
      });
      element.addEventListener("touchmove", onElementTouchMove, {
        passive: false,
      });
      return () => {
        element.removeEventListener("touchstart", onElementTouchStart);
        element.removeEventListener("touchmove", onElementTouchMove);
      };
    }
  }, [ref.current, onElementTouchStart, onElementTouchMove]);

  React.useEffect(() => {
    if (isDragging) {
      const onGlobalMouseUp = () => setIsDragging(false);
      const onGlobalMouseMove = (e: MouseEvent) => {
        props.onDrag({
          dx: e.movementX,
          dy: e.movementY,
        });
      };
      const onGlobalTouchEnd = (e: TouchEvent) => {
        const removeIds = Array.from(e.changedTouches).map(
          (touch) => touch.identifier
        );
        const newActiveTouches = activeTouches.filter(
          (at) => !removeIds.includes(at.id)
        );
        setActiveTouches(newActiveTouches);
        if (newActiveTouches.length === 0) {
          setIsDragging(false);
        }
      };
      document.addEventListener("mousemove", onGlobalMouseMove);
      document.addEventListener("touchend", onGlobalTouchEnd);
      document.addEventListener("mouseup", onGlobalMouseUp);
      return () => {
        document.removeEventListener("mousemove", onGlobalMouseMove);
        document.removeEventListener("touchend", onGlobalTouchEnd);
        document.removeEventListener("mouseup", onGlobalMouseUp);
      };
    }
  }, [
    activeTouches,
    setActiveTouches,
    isDragging,
    props.onDrag,
    setIsDragging,
  ]);

  React.useEffect(() => {
    if (!isFirstRender) {
      props.onDragStatusChange(isDragging);
    } else {
      setIsFirstRender(false);
    }
  }, [isDragging, isFirstRender, setIsFirstRender]);

  return (
    <div ref={ref} className={props.className} onMouseDown={onElementMouseDown}>
      {props.children}
    </div>
  );
}
