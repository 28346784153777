import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface TaskbarSliceState {
  isMainMenuOpen: boolean;
}

const initialState: TaskbarSliceState = {
  isMainMenuOpen: false,
};

export const taskbarSlice = createSlice({
  name: "taskbar",
  initialState,
  reducers: {
    setIsMainMenuOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isMainMenuOpen = payload;
    },
  },
});

export const { setIsMainMenuOpen } = taskbarSlice.actions;
export const taskbarReducer = taskbarSlice.reducer;
export const taskbarSelector = (state: RootState) => state.taskbar;
