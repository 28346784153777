export enum DesktopId {
  _404 = "_404",
  APPS_SPOTIFY = "APPS_SPOTIFY",
  APPS_SPOTIFY_TERMS = "APPS_SPOTIFY_TERMS",
  APPS_SCTG = "APPS_SCTG",
  HOME = "HOME",
  RESUME = "RESUME",
  MODALS_CONTAINER = "MODALS_CONTAINER",
  POSTS = "POSTS",
  POSTS_LOFI = "POSTS_LOFI",
  POSTS_MSDOS = "POSTS_MSDOS",
  POSTS_TEXT_SHADOW = "POSTS_TEXT_SHADOW",
}
