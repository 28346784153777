import React from "react";
import styled, { useTheme } from "styled-components";
import { LinePosition, LinkButton } from "../../atoms/button";

export function StartMenu() {
  const theme = useTheme();
  return (
    <StyledStartMenu>
      <LinksSection>
        <LinkButton link={{ to: "/" }} linePosition={LinePosition.LEFT}>
          Home
        </LinkButton>
        <LinkButton link={{ to: "/posts" }} linePosition={LinePosition.LEFT}>
          Posts
        </LinkButton>
      </LinksSection>
      <AppsSection>
        <LinkButton
          link={{ to: "/apps/spotify-screenshot-generator" }}
          colorBackground={theme.colors.gui.startMenuAppsButton}
        >
          Spotify Screenshot Generator
        </LinkButton>
        <LinkButton
          link={{ to: "/apps/subler-chapter-text-generator" }}
          colorBackground={theme.colors.gui.startMenuAppsButton}
        >
          Subler Chapter Text Generator
        </LinkButton>
        <LinkButton
          link={{ to: "/resume" }}
          colorBackground={theme.colors.gui.startMenuAppsButton}
        >
          Resume
        </LinkButton>
      </AppsSection>
    </StyledStartMenu>
  );
}

const StyledAppsSection = styled.div`
  display: grid;
  align-content: flex-start;
  background: ${(props) => props.theme.colors.palette.white.darker};
  color: ${(props) => props.theme.colors.palette.black.lighter};
  * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

interface AppsSectionProps {
  children: React.ReactNode;
}

const AppsSection: React.FC<AppsSectionProps> = ({ children }) => {
  return <StyledAppsSection>{children}</StyledAppsSection>;
};

const StyledLinksSection = styled.div`
  display: grid;
  align-content: flex-start;
  height: fit-content;
  background: ${(props) => props.theme.colors.gui.windowBackground};
`;

interface LinksSectionProps {
  children: React.ReactNode;
}

const LinksSection: React.FC<LinksSectionProps> = ({ children }) => {
  return <StyledLinksSection>{children}</StyledLinksSection>;
};

const StyledStartMenu = styled.menu`
  background-color: ${(props) => props.theme.colors.gui.toolbarBackground};
  padding: ${(props) => props.theme.spacings.panels.padding}px;
  width: 90vw;
  max-width: 500px;

  display: grid;
  gap: ${(props) => props.theme.spacings.panels.gap}px;
  grid-template-areas:
    "StyledLinksSection"
    "StyledAppsSection";
  @media (min-width: 400px) {
    grid-template-areas: "StyledLinksSection StyledAppsSection";
    grid-template-columns: min-content minmax(0, 1fr);
  }
  ${StyledAppsSection} {
    grid-area: StyledAppsSection;
  }
  ${StyledLinksSection} {
    grid-area: StyledLinksSection;
  }
`;
