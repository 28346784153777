import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { addAppMiddlewareListener } from "../../app/middleware";
import { RootState } from "../../app/store";
import { setIsMainMenuOpen } from "../taskbar/taskbar-slice";

interface TaskbarSliceState {
  hasOpenedMainMenu: boolean;
}

const initialState: TaskbarSliceState = {
  hasOpenedMainMenu: false,
};

export const welcomeSlice = createSlice({
  name: "welcome",
  initialState,
  reducers: {
    setHasOpenedMainMenu: (state, { payload }: PayloadAction<boolean>) => {
      state.hasOpenedMainMenu = payload;
    },
  },
});

export const { setHasOpenedMainMenu } = welcomeSlice.actions;
export const welcomeSelector = (state: RootState) => state.welcome;
export const welcomeReducer = persistReducer(
  {
    key: "welcome",
    storage,
  },
  welcomeSlice.reducer
);

addAppMiddlewareListener({
  actionCreator: setIsMainMenuOpen,
  effect: (_action, { dispatch }) => {
    const { setHasOpenedMainMenu } = welcomeSlice.actions;
    dispatch(setHasOpenedMainMenu(true));
  },
});
