import { useDebounce } from "@react-hook/debounce";
import useResizeObserver, {
  UseResizeObserverCallback,
} from "@react-hook/resize-observer";
import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { useAppSelector } from "../../../app/hooks";
import { modalsSelector } from "../../../store/modals/modals-slice";
import { DESKTOP_LAYOUT_FLOATING_MARGINS } from "./desktop-layout-floating";

// Used for preventing right/bottom window drag from expanding document
// Also ensuring it grows to fill viewport, if content is smaller than viewport
const StyledDesktopRootWrapper = styled.div.attrs<{ $inert: boolean }>(
  (props) => ({
    inert: props.$inert ? "" : undefined,
  })
)<{ $inert: boolean }>`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
`;

// Used for desktop window intersection (out-of-bounds) detection
const StyledDesktopOutOfBoundsContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  margin: 60px 12px 12px;
  padding: 40px 8px;
  @media (min-height: 1000px) {
    padding-top: 100px;
  }
  @media (min-width: 600px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export function SiteDesktopContainer(props: { children: React.ReactNode }) {
  const outOfBoundsRef = React.useRef<HTMLDivElement>(null);

  /**
   * A window will be considered out of bounds if it is below 100% threshold - it is out by even 1 pixel.
   * The bounds include the Desktop Layout's padding, but not the margins.
   * NOTE: isBoundsCheckRelativeToViewport is being passed as an arg here because the site-desktop-container doesn't know about the current desktop's settings
   */
  const useWindowOutOfBoundsObserver = React.useCallback(
    ({
      isActive,
      isBoundsCheckRelativeToViewport = false,
    }: {
      isActive: boolean;
      isBoundsCheckRelativeToViewport?: boolean;
    }) => {
      const { ref, inView, entry } = useInView({
        root: isBoundsCheckRelativeToViewport ? null : outOfBoundsRef.current,
        fallbackInView: true,
        initialInView: true,
        skip: !isActive,
        threshold: 1,
        rootMargin: isBoundsCheckRelativeToViewport
          ? `${-DESKTOP_LAYOUT_FLOATING_MARGINS.top}px ${-DESKTOP_LAYOUT_FLOATING_MARGINS.left}px ${-DESKTOP_LAYOUT_FLOATING_MARGINS.right}px ${-DESKTOP_LAYOUT_FLOATING_MARGINS.bottom}px`
          : undefined,
      });
      return {
        ref,
        isOutOfBounds: !inView,
        entry,
      };
    },
    [outOfBoundsRef.current]
  );

  const [
    isDesktopResizing,
    setIsDesktopResizingDebounced,
    setIsDesktopResizingImmediate,
  ] = useDebounce(false, 250);
  const resizeObserverCallback = React.useCallback<UseResizeObserverCallback>(
    (_entry) => {
      setIsDesktopResizingImmediate(true);
      setIsDesktopResizingDebounced(false);
    },
    [setIsDesktopResizingImmediate, setIsDesktopResizingDebounced]
  );
  useResizeObserver(outOfBoundsRef, resizeObserverCallback);

  const { openModals } = useAppSelector(modalsSelector);
  const areModalsOpen = !!openModals.length;

  return (
    <StyledDesktopRootWrapper $inert={areModalsOpen}>
      <StyledDesktopOutOfBoundsContainer ref={outOfBoundsRef}>
        <SiteDesktopContainerContext.Provider
          value={{
            isDesktopResizing,
            useWindowOutOfBoundsObserver,
          }}
        >
          {props.children}
        </SiteDesktopContainerContext.Provider>
      </StyledDesktopOutOfBoundsContainer>
    </StyledDesktopRootWrapper>
  );
}

interface WindowOutOfBoundsObserverResponse {
  ref: (node?: Element | null) => void;
  isOutOfBounds: boolean;
  entry: IntersectionObserverEntry | undefined;
}

interface SiteDesktopContainerContextValue {
  isDesktopResizing: boolean;
  useWindowOutOfBoundsObserver: (args: {
    isActive: boolean;
    isBoundsCheckRelativeToViewport: boolean;
  }) => WindowOutOfBoundsObserverResponse;
}

export const SiteDesktopContainerContext =
  React.createContext<SiteDesktopContainerContextValue>({
    isDesktopResizing: false,
    useWindowOutOfBoundsObserver: () => ({
      ref: () => {},
      isOutOfBounds: false,
      entry: undefined,
    }),
  });
