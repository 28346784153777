import type { PaletteHsl } from "./compute-color-palette-types";

export async function computeColorPalette(
  url: string,
  paletteSize: number
): Promise<PaletteHsl> {
  const dimension = 128;
  const image = await loadUrlAsImage(url);
  return new Promise((resolve) => {
    const { imageData, width, height } = getImageDataFromImage(
      image,
      dimension
    );
    const worker = new Worker(
      new URL("./compute-color-palette.worker.ts", import.meta.url)
    );
    worker.onmessage = ({ data: { paletteHsl } }) => {
      const processedPalette = processPalette(paletteHsl);
      resolve(processedPalette);
    };
    worker.postMessage(
      { imageDataBuffer: imageData.data.buffer, width, height, paletteSize },
      [imageData.data.buffer]
    );
  });
}

export async function loadUrlAsImage(url: string): Promise<HTMLImageElement> {
  const img = new Image();
  return new Promise((resolve) => {
    img.onload = () => resolve(img);
    img.src = url;
  });
}

function getImageDataFromImage(image: HTMLImageElement, maxDimension: number) {
  const canvas = document.createElement("canvas");
  const w = image.naturalWidth;
  const h = image.naturalHeight;
  if (w > h) {
    canvas.width = maxDimension;
    canvas.height = Math.floor(h * (maxDimension / w));
  } else {
    canvas.width = Math.floor(w * (maxDimension / h));
    canvas.height = maxDimension;
  }
  const ctx = canvas.getContext("2d");
  if (ctx === null) {
    throw "Unable to get CanvasRenderingContext2D to calculate color scheme";
  }
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  return {
    imageData: ctx.getImageData(0, 0, canvas.width, canvas.height),
    width: canvas.width,
    height: canvas.height,
  };
}

/**
 * Clamps saturation and luminosity values
 * TODO: Weighted ranking based on balance of most common vs. filtering out greys (if possible)
 * Maybe change this to return "most common", "most saturation", "most luminosity"???
 */
function processPalette(palette: PaletteHsl): PaletteHsl {
  return [...palette].map((c) => ({
    h: c.h,
    s: inRange(0, c.s, 50),
    l: inRange(30, c.l, 50),
  }));
}

function inRange(min: number, value: number, max: number): number {
  return Math.max(min, Math.min(value, max));
}
