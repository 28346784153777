import type { TypedStartListening } from "@reduxjs/toolkit";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import type { AppDispatch, RootState } from "./store";

export const appListenerMiddleware = createListenerMiddleware();

export const addAppMiddlewareListener =
  appListenerMiddleware.startListening as TypedStartListening<
    RootState,
    AppDispatch
  >;
