import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DesktopId } from "../../app/desktops";
import { addAppMiddlewareListener } from "../../app/middleware";
import { RootState } from "../../app/store";
import type { ModalSiteCustomizeOptions } from "../../components/site-settings/modal-customize/modal-site-customize";
import { flushDesktopWindowStateData } from "../desktops/desktops-slice";

export enum ModalType {
  APPS_SCTG_ABOUT,
  APPS_SCTG_REMOVE_CHAPTERS,
  SITE_CUSTOMIZE,
  TEST_MODAL,
}

export type ModalConfig =
  | { type: ModalType.APPS_SCTG_ABOUT; options: {} }
  | { type: ModalType.APPS_SCTG_REMOVE_CHAPTERS; options: {} }
  | { type: ModalType.SITE_CUSTOMIZE; options: ModalSiteCustomizeOptions }
  | { type: ModalType.TEST_MODAL; options: { num: number } };

export type ModalConfigWithId = ModalConfig & { id: string };

export interface ModalsState {
  openModals: ModalConfigWithId[];
}

const initialState: ModalsState = {
  openModals: [],
};

let modalIdCounter = 0;

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    pushModal: (state, { payload }: PayloadAction<ModalConfig>) => {
      state.openModals.push({
        ...payload,
        id: `${modalIdCounter++}-${payload.type}`,
      });
    },
    popModal: (state, {}: PayloadAction<void>) => {
      state.openModals.pop();
    },
  },
});

export const { pushModal, popModal } = modalsSlice.actions;
export const modalsSelector = (state: RootState) => state.modals;
export const modalsReducer = modalsSlice.reducer;

// Clean up modals desktop state when modals are closed (avoid memory leak)
addAppMiddlewareListener({
  actionCreator: popModal,
  effect: (_action, { dispatch, getState }) => {
    const {
      modals: { openModals },
    } = getState();
    if (openModals.length === 0) {
      dispatch(
        flushDesktopWindowStateData({ desktopId: DesktopId.MODALS_CONTAINER })
      );
    }
  },
});
