import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

export interface SctgChapter {
  label: string;
  timestampMs: number;
}

export interface SublerChapterTextGeneratorState {
  sctgChapters: SctgChapter[];
  sctgEditingChapterName: string;
  sctgVideoPlaybackTimestampMs: number;
  sctgVideoTitle: string | null;
  sctgVideoUrl: string | null;
}

const initialState: SublerChapterTextGeneratorState = {
  sctgChapters: [],
  sctgEditingChapterName: "",
  sctgVideoPlaybackTimestampMs: 0,
  sctgVideoTitle: null,
  sctgVideoUrl: null,
};

export const sublerChapterTextGeneratorSlice = createSlice({
  name: "SublerChapterTextGenerator",
  initialState,
  reducers: {
    insertOrUpdateSctgChapter: (
      state,
      { payload }: PayloadAction<SctgChapter>
    ) => {
      const newChapters = [
        ...state.sctgChapters.filter(
          (c) => c.timestampMs !== payload.timestampMs
        ),
        payload,
      ];
      newChapters.sort((a, b) => a.timestampMs - b.timestampMs);
      state.sctgChapters = newChapters;
    },
    removeSctgChapter: (state, { payload }: PayloadAction<number>) => {
      state.sctgChapters = state.sctgChapters.filter(
        (c) => c.timestampMs !== payload
      );
    },
    resetAll: (_state, {}: PayloadAction<void>) => {
      return initialState;
    },
    setSctgEditingChapterName: (state, { payload }: PayloadAction<string>) => {
      state.sctgEditingChapterName = payload;
    },
    setSctgVideoPlaybackTimestampMs: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.sctgVideoPlaybackTimestampMs = payload;
    },
    setSctgVideoTitle: (state, { payload }: PayloadAction<string>) => {
      state.sctgVideoTitle = payload;
    },
    setSctgVideoUrl: (state, { payload }: PayloadAction<string>) => {
      state.sctgVideoUrl = payload;
    },
  },
});

export const {
  insertOrUpdateSctgChapter,
  removeSctgChapter,
  resetAll,
  setSctgEditingChapterName,
  setSctgVideoPlaybackTimestampMs,
  setSctgVideoTitle,
  setSctgVideoUrl,
} = sublerChapterTextGeneratorSlice.actions;

export const setSctgVideoFromFile = createAsyncThunk(
  "SublerChapterTextGenerator/setAlbumArt",
  async (file: File, { dispatch, getState }) => {
    const {
      apps: {
        sublerChapterTextGenerator: { sctgVideoUrl },
      },
    } = getState() as RootState;
    if (sctgVideoUrl) {
      URL.revokeObjectURL(sctgVideoUrl);
    }
    const newObjectUrl = URL.createObjectURL(file);
    dispatch(setSctgVideoTitle(file.name));
    dispatch(setSctgVideoUrl(newObjectUrl));
  }
);

export const sublerChapterTextGeneratorSelector = (rootState: RootState) => {
  return rootState.apps.sublerChapterTextGenerator;
};
export const sublerChapterTextGeneratorReducer =
  sublerChapterTextGeneratorSlice.reducer;
