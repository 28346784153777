import React from "react";
import styled from "styled-components";
import {
  BaseDesktopLayout,
  BaseDesktopLayoutProps,
} from "./base-desktop-layout";

export const DESKTOP_LAYOUT_FLOATING_MARGINS = Object.freeze({
  top: 60,
  left: 12,
  right: 12,
  bottom: 12,
});

const StyledDesktopLayoutFloating = styled(BaseDesktopLayout)`
  position: fixed;
  top: ${DESKTOP_LAYOUT_FLOATING_MARGINS.top}px;
  left: ${DESKTOP_LAYOUT_FLOATING_MARGINS.left}px;
  width: calc(
    100% -
      ${DESKTOP_LAYOUT_FLOATING_MARGINS.left +
      DESKTOP_LAYOUT_FLOATING_MARGINS.right}px
  );
  height: calc(
    100% -
      ${DESKTOP_LAYOUT_FLOATING_MARGINS.top +
      DESKTOP_LAYOUT_FLOATING_MARGINS.bottom}px
  );
  display: flex;
  align-items: center;
  justify-content: center;

  /* TODO: this is hacky - ProgramWindow needs to be treated as a black box, this violates that. Extract the window wrapper and give it more props. */
  > * {
    position: fixed !important;
  }
`;

interface DesktopLayoutFloatingProps
  extends Omit<
    BaseDesktopLayoutProps,
    "canWindowsRearrange" | "isBoundsCheckRelativeToViewport"
  > {}

export const DesktopLayoutFloating = React.forwardRef(
  (
    props: DesktopLayoutFloatingProps,
    ref: React.ForwardedRef<HTMLElement | null>
  ) => {
    return (
      <StyledDesktopLayoutFloating
        ref={ref}
        {...props}
        canWindowsRearrange={true}
        isBoundsCheckRelativeToViewport={true}
      />
    );
  }
);
