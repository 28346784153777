import * as React from "react";
import styled from "styled-components";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  modalsSelector,
  ModalType,
  pushModal,
} from "../../../store/modals/modals-slice";
import { Button, LinePosition } from "../../gui/atoms/button";
import { StartMenuButton } from "../../gui/organisms/taskbar/start-menu-button";

const StyledTaskbar = styled.nav.attrs<{ $inert: boolean }>((props) => ({
  inert: props.$inert ? "" : undefined,
}))<{ $inert: boolean }>`
  // Needs to be position: fixed because of gatsby-plugin-transition-link interactions; however set it back to sticky for printing so that we don't show taskbar on every page
  position: fixed;
  @media print {
    position: sticky;
  }
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${(props) => props.theme.spacings.controls.gap}px;

  background-color: ${(props) => props.theme.colors.gui.toolbarBackground};
  box-shadow: ${(props) => props.theme.shadows.soft.medium};

  font-family: ${(props) => props.theme.fonts.family.gui};
`;

export default function SiteTaskbar(): JSX.Element {
  const dispatch = useAppDispatch();
  const { openModals } = useAppSelector(modalsSelector);
  const areModalsOpen = !!openModals.length;
  return (
    <StyledTaskbar $inert={areModalsOpen}>
      <div>
        <StartMenuButton />
      </div>
      <div>
        <Button
          onClick={() =>
            dispatch(pushModal({ type: ModalType.SITE_CUSTOMIZE, options: {} }))
          }
          faIcon={{
            icon: faSlidersH,
            rotation: 90,
          }}
          linePosition={LinePosition.BOTTOM}
        >
          Customize
        </Button>
      </div>
    </StyledTaskbar>
  );
}
