import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { spotifyScreenshotGeneratorReducer } from "../store/apps/spotify-screenshot-generator/spotify-screenshot-generator-slice";
import { sublerChapterTextGeneratorReducer } from "../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-slice";
import { desktopsReducer } from "../store/desktops/desktops-slice";
import { modalsReducer } from "../store/modals/modals-slice";
import { resumeConfigReducer } from "../store/resume/resume-slice";
import { taskbarReducer } from "../store/taskbar/taskbar-slice";
import { themeConfigReducer } from "../store/theme-config/theme-config-slice";
import { welcomeReducer } from "../store/welcome/welcome-slice";
import { appListenerMiddleware } from "./middleware";

const appsReducer = combineReducers({
  spotifyScreenshotGenerator: spotifyScreenshotGeneratorReducer,
  sublerChapterTextGenerator: sublerChapterTextGeneratorReducer,
});

const store = configureStore({
  reducer: {
    themeConfig: themeConfigReducer,
    modals: modalsReducer,
    resumeConfig: resumeConfigReducer,
    apps: appsReducer,
    desktops: desktopsReducer,
    taskbar: taskbarReducer,
    welcome: welcomeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(appListenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
export default store;
