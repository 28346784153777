import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  taskbarSelector,
  setIsMainMenuOpen,
} from "../../../../store/taskbar/taskbar-slice";
import { LinePosition } from "../../atoms/button";
import { ToggleButtonMenu } from "../../molecules/toggle-button-menu";
import { StartMenu } from "./start-menu";

export function StartMenuButton() {
  const dispatch = useAppDispatch();
  const { isMainMenuOpen } = useAppSelector(taskbarSelector);
  const onMainMenuClick = React.useCallback(
    () => dispatch(setIsMainMenuOpen(!isMainMenuOpen)),
    [dispatch, setIsMainMenuOpen, isMainMenuOpen]
  );
  return (
    <ToggleButtonMenu
      isOpen={isMainMenuOpen}
      toggleIsOpen={onMainMenuClick}
      button={{
        faIcon: {
          icon: faBars,
        },
        children: <strong>Menu</strong>,
        linePosition: LinePosition.BOTTOM,
      }}
      menu={<StartMenu />}
    />
  );
}
