import * as React from "react";
import { ProgramWindow } from "../../gui/organisms/program-window/program-window";
import type { ProgramWindowProps } from "../../gui/organisms/program-window/program-window";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ModalContext } from "../../../app/modals";
import { useAppDispatch } from "../../../app/hooks";
import { popModal } from "../../../store/modals/modals-slice";

let modalIdCounter = 0;

type SiteModalProps = {} & Pick<ProgramWindowProps, "title" | "children">;

export function SiteModal(props: SiteModalProps) {
  const modalContext = React.useContext(ModalContext);
  const dispatch = useAppDispatch();
  const windowId = `${props.title}-${modalIdCounter++}`;
  return (
    <ProgramWindow
      windowId={windowId}
      title={props.title}
      buttons={[
        {
          id: "close3",
          label: (
            // TODO: common set of frequently used icons?
            <FontAwesomeIcon
              icon={faXmark}
              size="lg"
              style={{ margin: "-0.1em 0.1em" }}
            />
          ),
          alt: "Close Settings",
          isDisabled: !modalContext.isActive,
          onClick: () => dispatch(popModal()),
        },
      ]}
    >
      {props.children}
    </ProgramWindow>
  );
}
