import { useTriggerTransition } from "gatsby-plugin-transition-link";
import { useTheme } from "styled-components";
import type { DefaultTheme } from "styled-components";

export const getDefaultSiteTransitionOptions = (theme: DefaultTheme) => ({
  exit: { length: theme.timings.pageTransitionSeconds / 2 },
  entry: {
    // Not fully understanding the observed behaviour - delay 0 causes visible scroll jump, full delay causes very long transitions
    delay: theme.timings.pageTransitionSeconds / 2,
    length: theme.timings.pageTransitionSeconds / 2,
  },
});

export type TriggerSiteTransition = ({ event }?: { event?: Event }) => void;

export function useSiteTransition({
  to,
}: {
  to: string;
}): TriggerSiteTransition {
  const theme = useTheme();
  const options = getDefaultSiteTransitionOptions(theme);
  const triggerTransition = useTriggerTransition({
    entry: options.entry,
    exit: options.exit,
    to,
  });
  // triggerTransition cannot be called without args, since it expects an object
  return ({ event }: { event?: Event } = {}) => triggerTransition({ event });
}
