import TransitionLink, {
  TransitionLinkProps,
} from "gatsby-plugin-transition-link";
import * as React from "react";
import { useTheme } from "styled-components";
import { getDefaultSiteTransitionOptions } from "./use-site-transition";

export type SiteTransitionLinkProps = Omit<
  TransitionLinkProps,
  "ref" | "exit" | "entry"
> & {
  // TODO: determine if ref is needed?
};

export const SiteTransitionLink = ({
  className,
  children,
  ...props
}: SiteTransitionLinkProps) => {
  const theme = useTheme();
  const options = getDefaultSiteTransitionOptions(theme);
  return (
    <TransitionLink
      className={className}
      exit={options.exit}
      entry={options.entry}
      {...props}
    >
      {children}
    </TransitionLink>
  );
};
