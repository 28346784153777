import * as React from "react";
import styled from "styled-components";

const StyledPostContent = styled.div`
  font-family: ${(props) => props.theme.fonts.family.body};
  a {
    color: ${(props) => props.theme.colors.text.link};
    text-decoration: underline;
  }
  h1 {
    margin-bottom: 0.5em;
    /* TODO: maybe put this in some global styles? Or atoms? */
    a {
      color: ${(props) => props.theme.colors.text.link};
      text-decoration: none;
      :hover,
      :focus {
        text-decoration: underline;
      }
    }
  }
  h2 {
    font-size: 1.375em;
  }
  p,
  ol,
  ul,
  pre {
    margin-bottom: 0.5em;
  }
  li > ol,
  li > ul {
    margin-bottom: 0;
  }
  ol,
  ul {
    margin-left: 2.5em;
  }
  code,
  pre {
    background-color: ${(props) => props.theme.colors.text.codeBackground};
    font-family: ${(props) => props.theme.fonts.family.mono};
  }
  code {
    white-space: nowrap;
  }
  pre {
    padding: 0.5em;
    overflow-x: auto;
  }
  h1 > code,
  h2 > code,
  h3 > code {
    background-color: transparent;
  }
`;

export const PostContent = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <StyledPostContent className={props.className}>
      {props.children}
    </StyledPostContent>
  );
};
