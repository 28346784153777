import * as React from "react";
import styled from "styled-components";
import { DragListenerBox } from "../../atoms/drag-listener-box";
import {
  ProgramTitlebarButtonConfig,
  ProgramTitlebarPosition,
} from "./program-window-types";

const StyledTitlebarTitle = styled.div`
  flex-grow: 1;
  font-family: ${(props) => props.theme.fonts.family.gui};
  min-height: 0.75ex;
`;

const StyledTitlebarButton = styled.button<{
  $position: ProgramTitlebarPosition;
}>`
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.family.gui};
  padding: 4px 4px;
  /* TODO: figure out what looks good for these buttons */
  background-color: ${(props) =>
    props.theme.colors.gui.titlebarButtonBackground};
  border: 2px solid ${(props) => props.theme.colors.gui.titlebarButtonBorder};
  min-width: 32px;
  color: ${(props) => props.theme.colors.gui.titlebarButtonLabel};
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    writing-mode: ${(props) =>
      props.$position === ProgramTitlebarPosition.LEFT
        ? "vertical-lr"
        : "horizontal-tb"};
  }
`;

const StyledTitlebarButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const StyledProgramTitlebar = styled(DragListenerBox)<{
  $position: ProgramTitlebarPosition;
}>`
  cursor: move;
  grid-area: ${(props) => props.$position};

  display: flex;
  padding: 8px;
  gap: 12px;
  align-items: center;
  min-height: 16px;

  background-image: linear-gradient(
    ${(props) =>
      props.$position === ProgramTitlebarPosition.LEFT
        ? "to bottom"
        : "to right"},
    ${(props) => props.theme.colors.gui.titlebarBarBackgroundStart},
    ${(props) => props.theme.colors.gui.titlebarBarBackgroundEnd}
  );
  color: ${(props) => props.theme.colors.gui.titlebarBarLabel};

  /* TODO: use sideways-lr for vertical when supported in other browsers */
  ${(props) =>
    props.$position === ProgramTitlebarPosition.LEFT &&
    `writing-mode: vertical-lr; transform: rotate(180deg);`}
`;

type ProgramTitlebarProps = {
  title: string;
  position: ProgramTitlebarPosition;
  buttons: ProgramTitlebarButtonConfig[];
  // This is a hack to simulataneously prevent dragging on window button, while also raising the window on mouse down
  raiseWindowCallback: () => void;
  onDragEvent: (e: { dx: number; dy: number }) => void;
  onDragStatusChange: (isDragging: boolean) => void;
};

export const ProgramTitlebar = (props: ProgramTitlebarProps) => {
  const onMouseDownAndTouchStartOverride = React.useCallback(
    (e: React.MouseEvent | TouchEvent) => {
      e.stopPropagation();
      props.raiseWindowCallback();
    },
    [props.raiseWindowCallback]
  );

  return (
    <StyledProgramTitlebar
      $position={props.position}
      onDragStatusChange={props.onDragStatusChange}
      onDrag={props.onDragEvent}
    >
      <StyledTitlebarTitle>{props.title}</StyledTitlebarTitle>
      <StyledTitlebarButtons>
        {props.buttons.map((button) => (
          <ProgramTitlebarButton
            key={button.id}
            config={button}
            position={props.position}
            onMouseDown={onMouseDownAndTouchStartOverride}
            onTouchStart={onMouseDownAndTouchStartOverride}
          />
        ))}
      </StyledTitlebarButtons>
    </StyledProgramTitlebar>
  );
};

const ProgramTitlebarButton = (props: {
  config: ProgramTitlebarButtonConfig;
  position: ProgramTitlebarPosition;
  onMouseDown: React.MouseEventHandler;
  onTouchStart: (e: TouchEvent) => void;
}) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  React.useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      element.addEventListener("touchstart", props.onTouchStart, {
        passive: false,
      });
      return () => {
        element.removeEventListener("touchstart", props.onTouchStart);
      };
    }
  }, [props.onTouchStart, ref.current]);
  return (
    <StyledTitlebarButton
      ref={ref}
      $position={props.position}
      onMouseDown={props.onMouseDown}
      onClick={props.config.onClick}
      aria-label={props.config.alt ?? null}
    >
      <span>{props.config.label}</span>
    </StyledTitlebarButton>
  );
};
