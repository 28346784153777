import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import styled from "styled-components";
import { PostContent } from "../../gui/atoms/post-content";
import { SiteModal } from "../../layout/modals/site-modal";

const StyledSctgAboutContent = styled(PostContent)`
  max-width: 500px;
`;

export function ModalAppsSctgAbout(_props: { options: {} }) {
  return (
    <SiteModal title="About Subler Chapter Text Generator">
      <StyledSctgAboutContent>
        <p>
          <OutboundLink href="https://subler.org/" target="_blank">
            Subler
          </OutboundLink>{" "}
          is an open source program for macOS that, among many other features,
          allows you to set the chapter markers for mp4 files.
        </p>
        <p>
          <strong>Subler Chapter Text Generator</strong> helps you to create
          frame-perfect chapter markers, which you can import into Subler.
        </p>
        <p>
          For details about the Chapter Text Format, check out{" "}
          <OutboundLink
            href="https://bitbucket.org/galad87/subler/wiki/Chapter%20Text%20Format"
            target="_blank"
          >
            Subler's documentation
          </OutboundLink>
          .
        </p>
      </StyledSctgAboutContent>
    </SiteModal>
  );
}
