import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { sctgFormatTimestamp } from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-serialize";
import {
  removeSctgChapter,
  sublerChapterTextGeneratorSelector,
} from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-slice";
import { popModal } from "../../../store/modals/modals-slice";
import { Button, LinePosition } from "../../gui/atoms/button";
import { PostContent } from "../../gui/atoms/post-content";
import { SiteModal } from "../../layout/modals/site-modal";

const StyledSctgRemoveContent = styled(PostContent)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.panels.gap}px;
  max-height: calc(100vh - 240px);
  overflow: auto;
  > div {
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacings.panels.gap}px;
  }
  button {
    display: inline;
    margin-left: auto;
  }
`;

const StyledCloseButtonWrapper = styled.div`
  padding: ${(props) => props.theme.spacings.panels.padding}px 0;
  display: flex;
  justify-content: center;
`;

export function ModalAppsSctgRemoveChapters(_props: { options: {} }) {
  const { sctgChapters } = useAppSelector(sublerChapterTextGeneratorSelector);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (sctgChapters.length === 0) {
      dispatch(popModal());
    }
  }, [dispatch, popModal, sctgChapters]);
  return (
    <SiteModal title="Remove Chapters">
      <StyledSctgRemoveContent>
        {sctgChapters.map((c) => (
          <div key={c.timestampMs}>
            {sctgFormatTimestamp(c.timestampMs)} <strong>{c.label}</strong>
            <Button
              onClick={() => {
                dispatch(removeSctgChapter(c.timestampMs));
              }}
              linePosition={LinePosition.BOTTOM}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        ))}
      </StyledSctgRemoveContent>
      <StyledCloseButtonWrapper>
        <Button
          onClick={() => dispatch(popModal())}
          linePosition={LinePosition.BOTTOM}
        >
          Close
        </Button>
      </StyledCloseButtonWrapper>
    </SiteModal>
  );
}
