import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addAppMiddlewareListener } from "../../../app/middleware";
import { RootState } from "../../../app/store";
import { computeColorPalette } from "./compute-color-palette";
import type {
  PaletteHsl,
  PaletteHslColor,
} from "./compute-color-palette-types";

type SongPalette = {
  appBackground: string;
  lyricsBackground: string;
};

export enum SpotifyGeneratorQueueType {
  ALBUM = "Album",
  PLAYLIST = "Playlist",
  PODCAST = "Podcast",
  YOUR_LIBRARY = "Your Library",
}

export enum SpotifyGeneratorRepeatMode {
  NO_REPEAT = "Repeat off",
  REPEAT_ONE = "Repeat one",
  REPEAT_ALL = "Repeat all",
}

export interface SpotifyScreenshotGeneratorState {
  albumArtUrl: string | null;
  artists: string;
  isBatteryLow: boolean;
  isShuffleActive: boolean;
  queueName: string;
  queueType: SpotifyGeneratorQueueType;
  repeatMode: SpotifyGeneratorRepeatMode;
  showStatusBar: boolean;
  songHasLyrics: boolean;
  songIsLiked: boolean;
  songIsPlaying: boolean;
  songLength: number | null;
  songName: string;
  songPalette: SongPalette;
  songPlaybackProgress: number | null;
}

const initialState: SpotifyScreenshotGeneratorState = {
  albumArtUrl: null,
  artists: "Artist(s)",
  isBatteryLow: false,
  isShuffleActive: false,
  queueName: "Album Name",
  queueType: SpotifyGeneratorQueueType.ALBUM,
  repeatMode: SpotifyGeneratorRepeatMode.NO_REPEAT,
  showStatusBar: false,
  songHasLyrics: false,
  songIsLiked: false,
  songIsPlaying: false,
  songLength: 210,
  songName: "Song Title",
  songPalette: {
    appBackground: "#777",
    lyricsBackground: "#333",
  },
  songPlaybackProgress: 0,
};

export const spotifyScreenshotGeneratorSlice = createSlice({
  name: "spotifyScreenshotGenerator",
  initialState,
  reducers: {
    resetAll: (_state, {}: PayloadAction<void>) => {
      return initialState;
    },
    setAlbumArtUrl: (state, { payload }: PayloadAction<string>) => {
      state.albumArtUrl = payload;
    },
    setArtists: (state, { payload }: PayloadAction<string>) => {
      state.artists = payload;
    },
    setIsBatteryLow: (state, { payload }: PayloadAction<boolean>) => {
      state.isBatteryLow = payload;
    },
    setIsShuffleActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isShuffleActive = payload;
    },
    setQueueName: (state, { payload }: PayloadAction<string>) => {
      state.queueName = payload;
    },
    setQueueType: (
      state,
      { payload }: PayloadAction<SpotifyGeneratorQueueType>
    ) => {
      const oldQueueType = state.queueType;
      state.queueType = payload;
      // If album name was the default name, then change it to the new default name
      const defaultQueueName = getDefaultQueueName(oldQueueType);
      if (state.queueName === defaultQueueName) {
        state.queueName = getDefaultQueueName(payload);
      }
      // If track name was the default name, then change it to the new default name
      const defaultSongName = getDefaultTrackName(oldQueueType);
      if (state.songName === defaultSongName) {
        state.songName = getDefaultTrackName(payload);
      }
    },
    setShowStatusBar: (state, { payload }: PayloadAction<boolean>) => {
      state.showStatusBar = payload;
    },
    setSongHasLyrics: (state, { payload }: PayloadAction<boolean>) => {
      state.songHasLyrics = payload;
    },
    setSongIsLiked: (state, { payload }: PayloadAction<boolean>) => {
      state.songIsLiked = payload;
    },
    setSongIsPlaying: (state, { payload }: PayloadAction<boolean>) => {
      state.songIsPlaying = payload;
    },
    setSongLength: (state, { payload }: PayloadAction<number | null>) => {
      state.songLength = payload;
    },
    setSongName: (state, { payload }: PayloadAction<string>) => {
      state.songName = payload;
    },
    setSongPalette: (state, { payload }: PayloadAction<SongPalette>) => {
      state.songPalette = payload;
    },
    setSongPlaybackProgress: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.songPlaybackProgress = payload;
    },
    setRepeatMode: (
      state,
      { payload }: PayloadAction<SpotifyGeneratorRepeatMode>
    ) => {
      state.repeatMode = payload;
    },
  },
});

export const setAlbumArtFromFile = createAsyncThunk(
  "spotifyScreenshotGenerator/setAlbumArt",
  async (file: File, { dispatch }) => {
    const newAlbumArtUrl = await imageFileToDataUrl(file);
    dispatch(
      spotifyScreenshotGeneratorSlice.actions.setAlbumArtUrl(newAlbumArtUrl)
    );
  }
);

function imageFileToDataUrl(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const result = reader.result as string;
      resolve(result);
    });
    reader.readAsDataURL(file);
  });
}

export const {
  resetAll,
  setAlbumArtUrl,
  setArtists,
  setIsBatteryLow,
  setIsShuffleActive,
  setQueueName,
  setQueueType,
  setShowStatusBar,
  setSongHasLyrics,
  setSongIsLiked,
  setSongIsPlaying,
  setSongLength,
  setSongName,
  setSongPalette,
  setSongPlaybackProgress,
  setRepeatMode,
} = spotifyScreenshotGeneratorSlice.actions;
export const spotifyScreenshotGeneratorSelector = (rootState: RootState) => {
  const state = rootState.apps.spotifyScreenshotGenerator;
  const isPodcast = state.queueType === SpotifyGeneratorQueueType.PODCAST;
  return {
    ...state,
    songHasLyrics: !isPodcast && state.songHasLyrics,
  };
};
export const spotifyScreenshotGeneratorReducer =
  spotifyScreenshotGeneratorSlice.reducer;

/**
 * When Album Art is set:
 *  - Asynchronously compute new color palette
 */
addAppMiddlewareListener({
  actionCreator: setAlbumArtUrl,
  effect: async (action, { dispatch }) => {
    if (action.payload === null) {
      return;
    }

    // TODO: make this a separate thunk?
    // TODO: some loading indicator maybe?
    // TODO: use HSL and sort by L
    const paletteHsl = await computeColorPalette(action.payload, 16);
    const hslCols = paletteHsl.map((c) => hslToHslString(c));
    dispatch(
      setSongPalette({
        appBackground: hslCols[0],
        lyricsBackground: hslCols[1],
      })
    );

    if (process.env.NODE_ENV === "development") {
      debugColorPaletteOutput(paletteHsl);
    }
  },
});

function hslToHslString({ h, s, l }: PaletteHslColor): string {
  return `hsl(${h}, ${s}%, ${l}%)`;
}

function debugColorPaletteOutput(hslCols: PaletteHsl) {
  console.log(hslCols);
  const bkg = `linear-gradient(to right, ${hslCols
    .map((c) => hslToHslString(c))
    .map((c) => `${c}, ${c}, ${c}, ${c}`)
    .join(", ")})`;
  // document.body.style.background = bkg;
}

function getDefaultQueueName(queueType: SpotifyGeneratorQueueType): string {
  switch (queueType) {
    case SpotifyGeneratorQueueType.ALBUM:
    case SpotifyGeneratorQueueType.PLAYLIST:
    case SpotifyGeneratorQueueType.PODCAST:
      return queueType + " Name";
    case SpotifyGeneratorQueueType.YOUR_LIBRARY:
      return "Liked Songs";
  }
}

function getDefaultTrackName(queueType: SpotifyGeneratorQueueType): string {
  switch (queueType) {
    case SpotifyGeneratorQueueType.ALBUM:
    case SpotifyGeneratorQueueType.PLAYLIST:
    case SpotifyGeneratorQueueType.YOUR_LIBRARY:
      return "Song Title";
    case SpotifyGeneratorQueueType.PODCAST:
      return "Episode Title";
  }
}
