import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { RootState } from "../../app/store";
import { getTheme } from "./site-theme";
import { ColorThemeName } from "./site-theme-types";

// TODO: Add versioning/migrations here before prod

export interface ThemeConfigState {
  colorThemeName: ColorThemeName;
}

const initialState: ThemeConfigState = {
  colorThemeName: ColorThemeName.LIGHT,
};

export const themeConfigSlice = createSlice({
  name: "themeConfig",
  initialState,
  reducers: {
    setColorThemeName: (state, { payload }: PayloadAction<ColorThemeName>) => {
      state.colorThemeName = payload;
    },
  },
});

export const { setColorThemeName } = themeConfigSlice.actions;
export const themeConfigSelector = (state: RootState) => state.themeConfig;
export const themeConfigReducer = persistReducer(
  {
    key: "themeConfig",
    storage,
  },
  themeConfigSlice.reducer
);

export const selectCurrentTheme = createSelector(
  (state: RootState) => state.themeConfig.colorThemeName,
  (colorThemeName) => {
    return getTheme({ colorThemeName });
  }
);
