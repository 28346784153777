import type { DOMRectJSON, WindowState } from "./desktops-types";

// Based on the current systemTranslateX/systemTranslateY, and available space (negative = out-of-bounds), compute new systemTranslateX/systemTranslateY
export function computeNewDesktopWindowSystemTranslate(
  windowState: WindowState,
  desk: DOMRectJSON,
  win: DOMRectJSON
): { newSystemTranslateX: number; newSystemTranslateY: number } {
  const { systemTranslateX, systemTranslateY } = windowState;

  const spaceTop = win.top - desk.top;
  const spaceLeft = win.left - desk.left;
  const spaceBottom = desk.bottom - win.bottom;
  const spaceRight = desk.right - win.right;
  /*
    For X dimension (repeat with Y):
    - (Negative space left AND right) (win wider than desk), move to align with left
    - Negative space left: move to align with left
    - Negative space right: move to align with right
    - Positive left AND right: reduce the absolute value of system translate by amount of available space (unless already at 0)
    */
  let newSystemTranslateX = 0;
  if (spaceLeft < 0 || win.width >= desk.width) {
    newSystemTranslateX = systemTranslateX - spaceLeft;
  } else if (spaceRight < 0) {
    newSystemTranslateX = systemTranslateX + spaceRight;
  } else {
    if (systemTranslateX < 0) {
      newSystemTranslateX = Math.min(0, systemTranslateX + spaceRight);
    } else {
      newSystemTranslateX = Math.max(0, systemTranslateX - spaceLeft);
    }
  }
  let newSystemTranslateY = 0;
  if (spaceTop < 0 || win.height >= desk.height) {
    newSystemTranslateY = systemTranslateY - spaceTop;
  } else if (spaceBottom < 0) {
    newSystemTranslateY = systemTranslateY + spaceBottom;
  } else {
    if (systemTranslateY < 0) {
      newSystemTranslateY = Math.min(0, systemTranslateY + spaceBottom);
    } else {
      newSystemTranslateY = Math.max(0, systemTranslateY - spaceTop);
    }
  }
  return {
    newSystemTranslateX,
    newSystemTranslateY,
  };
}
