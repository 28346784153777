import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { DesktopId } from "../../app/desktops";
import { RootState } from "../../app/store";
import { computeNewDesktopWindowSystemTranslate } from "./desktop-geometry-helpers";
import type { DesktopState, DOMRectJSON, WindowState } from "./desktops-types";

// TODO: introduce missing-key safety here?
interface DesktopSliceState {
  desktopStates: { [k in string]: DesktopState };
  windowStates: { [k in string]: WindowState };
  // windowStates: { [k in string]: WindowState | undefined };
}

const initialState: DesktopSliceState = {
  desktopStates: {
    ...Object.fromEntries(
      Object.values(DesktopId).map((desktopId) => [
        desktopId,
        {
          id: desktopId,
          canWindowsRearrange: true,
          windowIds: [],
        },
      ])
    ),
  },
  windowStates: {},
};

export const desktopsSlice = createSlice({
  name: "desktops",
  initialState,
  reducers: {
    ////////////////////////////////////////////////////////////////
    // Desktop Layout reducers
    // TODO: decide if we want to keep this reducer or change it
    flushDesktopWindowStateData: (
      state,
      { payload: { desktopId } }: PayloadAction<{ desktopId: DesktopId }>
    ) => {
      state.desktopStates[desktopId].windowIds.forEach((windowId) => {
        delete state.windowStates[windowId];
      });
      state.desktopStates[desktopId].windowIds = [];
    },
    resetDesktopWindowPositions: (
      state,
      { payload: { desktopId } }: PayloadAction<{ desktopId: DesktopId }>
    ) => {
      state.desktopStates[desktopId].windowIds.forEach((windowId) => {
        const windowState = state.windowStates[windowId];
        windowState.systemTranslateX = 0;
        windowState.systemTranslateY = 0;
        windowState.userTranslateX = 0;
        windowState.userTranslateY = 0;
      });
    },
    setDesktopLayoutCanWindowsRearrange: (
      state,
      {
        payload: { desktopId, value },
      }: PayloadAction<{ desktopId: DesktopId; value: boolean }>
    ) => {
      state.desktopStates[desktopId].canWindowsRearrange = value;
    },
    ////////////////////////////////////////////////////////////////
    // Desktop Window reducers
    bringWindowToFront: (
      state,
      {
        payload: { windowId },
      }: PayloadAction<{
        windowId: string;
      }>
    ) => {
      const windowState = state.windowStates[windowId];
      windowState.lastRaisedTime = new Date().getTime();
    },
    initOrUpdateDesktopWindow: (
      state,
      {
        payload: { desktopId, windowId, zIndexPriority = 0 },
      }: PayloadAction<{
        desktopId: DesktopId;
        windowId: string;
        zIndexPriority?: number;
      }>
    ) => {
      if (!state.windowStates[windowId]) {
        state.windowStates[windowId] = {
          id: windowId,
          desktopId: desktopId,
          lastRaisedTime: new Date().getTime(),
          isDragging: false,
          systemTranslateX: 0,
          systemTranslateY: 0,
          userTranslateX: 0,
          userTranslateY: 0,
          zIndexPriority,
        };
      } else {
        state.windowStates[windowId].zIndexPriority = zIndexPriority;
      }
      const { windowIds } = state.desktopStates[desktopId];
      if (!windowIds.includes(windowId)) {
        windowIds.push(windowId);
      }
    },
    // TODO: figure out why navigating from floating->static layout, then back, then forward again, causes
    recalculateDesktopWindowSystemTranslate: (
      state,
      {
        payload: { windowId, desk, win },
      }: PayloadAction<{
        windowId: string;
        desk: DOMRectJSON;
        win: DOMRectJSON;
      }>
    ) => {
      const windowState = state.windowStates[windowId];
      const { newSystemTranslateX, newSystemTranslateY } =
        computeNewDesktopWindowSystemTranslate(windowState, desk, win);
      windowState.systemTranslateX = newSystemTranslateX;
      windowState.systemTranslateY = newSystemTranslateY;
    },
    resetDesktopWindowUserTranslate: (
      state,
      {
        payload,
      }: PayloadAction<{
        windowId: string;
      }>
    ) => {
      const s = state.windowStates[payload.windowId];
      s.userTranslateX = 0;
      s.userTranslateY = 0;
      s.systemTranslateX = 0;
      s.systemTranslateY = 0;
    },
    setDesktopWindowIsDragging: (
      state,
      {
        payload: { windowId, isDragging },
      }: PayloadAction<{ windowId: string; isDragging: boolean }>
    ) => {
      state.windowStates[windowId].isDragging = isDragging;
    },
    shiftDesktopWindowUserTranslate: (
      state,
      {
        payload,
      }: PayloadAction<{
        windowId: string;
        dx: number;
        dy: number;
      }>
    ) => {
      const s = state.windowStates[payload.windowId];
      s.userTranslateX += payload.dx + s.systemTranslateX;
      s.userTranslateY += payload.dy + s.systemTranslateY;
      s.systemTranslateX = 0;
      s.systemTranslateY = 0;
    },
  },
});

export const {
  // DesktopStates
  flushDesktopWindowStateData,
  resetDesktopWindowPositions,
  setDesktopLayoutCanWindowsRearrange,
  // WindowStates
  bringWindowToFront,
  initOrUpdateDesktopWindow,
  recalculateDesktopWindowSystemTranslate,
  resetDesktopWindowUserTranslate,
  setDesktopWindowIsDragging,
  shiftDesktopWindowUserTranslate,
} = desktopsSlice.actions;
export const desktopsSelector = (state: RootState) => state.desktops;
export const desktopsReducer = desktopsSlice.reducer;

const selectDesktopState = (state: RootState, desktopId: DesktopId) =>
  state.desktops.desktopStates[desktopId];

const selectDesktopWindowsForDesktop = (
  state: RootState,
  desktopId: DesktopId
) => {
  return selectDesktopState(state, desktopId).windowIds.map(
    (windowId) => state.desktops.windowStates[windowId]
  );
};

export const selectDesktopWindowTranslate = (
  state: RootState,
  windowId: string
): { translateX: number; translateY: number } => {
  const {
    systemTranslateX = 0,
    systemTranslateY = 0,
    userTranslateX = 0,
    userTranslateY = 0,
  } = selectDesktopWindowState(state, windowId) ?? {};
  return {
    translateX: systemTranslateX + userTranslateX,
    translateY: systemTranslateY + userTranslateY,
  };
};

// TODO: check if recalculating this causes lag with many mouse inputs?
export const selectDesktopWindowZIndex = (
  state: RootState,
  desktopId: DesktopId,
  windowId: string
) => {
  const desktopState = selectDesktopState(state, desktopId);
  if (!desktopState || !desktopState.canWindowsRearrange) {
    return 0;
  }
  const { lastRaisedTime = 0, zIndexPriority = 0 } =
    selectDesktopWindowState(state, windowId) ?? {};
  const windowStates = selectDesktopWindowsForDesktop(state, desktopId);
  const windowsBelow = windowStates.filter((w) => {
    if (w.zIndexPriority < zIndexPriority) {
      return true;
    }
    if (w.zIndexPriority > zIndexPriority) {
      return false;
    }
    return w.lastRaisedTime < lastRaisedTime;
  });
  return windowsBelow.length;
};

export const selectDesktopWindowState = (
  state: RootState,
  windowId: string
): WindowState | undefined => {
  return state.desktops.windowStates[windowId];
};
