import type { SctgChapter } from "./subler-chapter-text-generator-slice";

// Given the timestamp as an integer number of milliseconds,
// Produces "hh:mm:ss.xxx"
export function sctgFormatTimestamp(timestampMs: number) {
  const xxx = timestampMs % 1000;
  timestampMs = (timestampMs - xxx) / 1000;

  const ss = timestampMs % 60;
  timestampMs = (timestampMs - ss) / 60;

  const mm = timestampMs % 60;
  timestampMs = (timestampMs - mm) / 60;

  const hh = timestampMs;

  return [
    String(hh).padStart(2, "0"),
    ":",
    String(mm).padStart(2, "0"),
    ":",
    String(ss).padStart(2, "0"),
    ".",
    String(xxx).padStart(3, "0"),
  ].join("");
}

export function sctgFormatChapterLine(chapter: SctgChapter) {
  const ts = sctgFormatTimestamp(chapter.timestampMs);
  return `${ts} ${chapter.label}`.trim();
}

export function sctgFormatChapterFile(
  chapters: SctgChapter[],
  options?: { watermark: boolean }
) {
  if (chapters.length === 0) {
    return ["#!", "# No chapters defined yet"].join("\n");
  }
  const watermarkLines = options?.watermark
    ? ["# https://www.simontang.dev/apps/subler-chapter-text-generator/"]
    : [];
  const chapterLines = chapters.map((c) => sctgFormatChapterLine(c));
  return ["#!", ...watermarkLines, "", ...chapterLines, ""].join("\n");
}
