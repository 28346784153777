import { DefaultTheme } from "styled-components";
import { ColorThemes } from "./site-theme-colors";
import { ColorThemeName, ThemeFontFamily } from "./site-theme-types";

const shadows = {
  // offset-x offset-y blur-radius spread-radius color
  soft: {
    light: `0 2px 8px 0 rgba(0, 0, 0, 0.3)`,
    medium: `0 4px 18px 0 rgba(0, 0, 0, 0.3)`,
    heavy: `0 12px 30px 0 rgba(0, 0, 0, 0.3)`,
  },
  square: {
    light: `3px 3px 0 0 rgba(0, 0, 0, 0.3)`,
    medium: `6px 6px 0 0 rgba(0, 0, 0, 0.3)`,
    heavy: `12px 12px 0 0 rgba(0, 0, 0, 0.3)`,
  },
};

const fonts: DefaultTheme["fonts"] = {
  family: {
    mono: ThemeFontFamily.SOURCE_CODE_PRO,
    gui: ThemeFontFamily.TITILLIUM_WEB,
    headings: ThemeFontFamily.TITILLIUM_WEB,
    body: ThemeFontFamily.TITILLIUM_WEB,
  },
};

const spacings: DefaultTheme["spacings"] = {
  buttons: {
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  controls: {
    gap: 4,
  },
  dropdowns: {
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  labels: {
    gap: 8,
  },
  panels: {
    gap: 8,
    padding: 8,
  },
};

const timings: DefaultTheme["timings"] = {
  pageTransitionSeconds: 0.45,
  guiFadeSeconds: 0.25,
};

export const getTheme = (args: {
  colorThemeName: ColorThemeName;
}): DefaultTheme => {
  const colors = ColorThemes[args.colorThemeName];
  return {
    colors,
    fonts,
    shadows,
    spacings,
    timings,
  };
};
