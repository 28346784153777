import {
  TransitionState,
  TransitionStateProps,
} from "gatsby-plugin-transition-link";
import * as React from "react";

// TODO: simulate the transition with timeouts?
// This is used because ProgramWindow is used in the modal container which isn't mounted inside the Transition state provider
const MockTransitionState = (props: TransitionStateProps) =>
  props.children({ mount: false, transitionStatus: "entered" });

export const MaybeTransitionState = ({ ...props }: TransitionStateProps) => {
  const useTransitionState = React.useContext(IsTransitionLinkAvailableContext);
  // TODO: manually animate on initial page load?
  return useTransitionState ? (
    <TransitionState>{props.children}</TransitionState>
  ) : (
    <MockTransitionState>{props.children}</MockTransitionState>
  );
};

export const IsTransitionLinkAvailableContext = React.createContext(false);
