import useResizeObserver, {
  UseResizeObserverCallback,
} from "@react-hook/resize-observer";
import React from "react";
import { mergeRefs } from "react-merge-refs";
import styled from "styled-components";
import { DesktopId } from "../../../app/desktops";
import { useAppDispatch } from "../../../app/hooks";
import { setDesktopLayoutCanWindowsRearrange } from "../../../store/desktops/desktops-slice";

const StyledBaseDesktopLayout = styled.main`
  display: none; // Prevent accidentally using this directly
  gap: 20px;
`;

export interface BaseDesktopLayoutProps {
  children: React.ReactNode;
  className?: string;
  desktopId: DesktopId;
  canWindowsRearrange?: boolean;
  // Determines the desktop boundaries are defined by viewport (no scrolling), or defined by content size (scrollable page)
  isBoundsCheckRelativeToViewport: boolean;
  onResize?: ({ width, height }: { width: number; height: number }) => void;
}

// Do not mount directly; extend with styled-components
const BaseDesktopLayoutInner = (
  props: BaseDesktopLayoutProps,
  ref: React.Ref<HTMLElement>
) => {
  const {
    desktopId,
    canWindowsRearrange = false,
    isBoundsCheckRelativeToViewport = false,
  } = props;

  const [desktopWidth, setDesktopWidth] = React.useState(0);
  const [desktopHeight, setDesktopHeight] = React.useState(0);

  const resizeRef = React.useRef<HTMLElement | null>(null);
  const mergedRef = mergeRefs([ref, resizeRef]);

  // TODO: A toggle in Settings to toggle site-wide "Allow window dragging (freeform)".
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(
      setDesktopLayoutCanWindowsRearrange({
        desktopId,
        value: canWindowsRearrange,
      })
    );
  }, [canWindowsRearrange]);

  const resizeHandler = React.useCallback<UseResizeObserverCallback>(
    (entry) => {
      // https://drafts.csswg.org/resize-observer/#dom-resizeobserverentry-borderboxsize
      // borderBoxSize[0] is the only expected value in the current spec
      const { inlineSize: width, blockSize: height } = entry.borderBoxSize[0];
      setDesktopWidth(width);
      setDesktopHeight(height);
      props.onResize?.({ width, height });
    },
    [setDesktopWidth, setDesktopHeight, props.onResize]
  );
  useResizeObserver(resizeRef.current, resizeHandler);

  return (
    <DesktopLayoutContext.Provider
      value={{
        desktopId,
        desktopWidth,
        desktopHeight,
        canWindowsRearrange,
        isBoundsCheckRelativeToViewport,
      }}
    >
      <StyledBaseDesktopLayout className={props.className} ref={mergedRef}>
        {props.children}
      </StyledBaseDesktopLayout>
    </DesktopLayoutContext.Provider>
  );
};

export const BaseDesktopLayout = React.forwardRef(BaseDesktopLayoutInner);

interface IDesktopLayoutContext {
  desktopId: DesktopId;
  desktopWidth: number;
  desktopHeight: number;
  canWindowsRearrange: boolean;
  isBoundsCheckRelativeToViewport: boolean;
}
export const DesktopLayoutContext =
  // @ts-expect-error We must never have a missing DesktopLayoutContext provider
  React.createContext<IDesktopLayoutContext>(null);
