import { useDebounce } from "@react-hook/debounce";
import { TransitionStatuses } from "gatsby-plugin-transition-link";
import * as React from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import {
  bringWindowToFront,
  initOrUpdateDesktopWindow,
  selectDesktopWindowTranslate,
  selectDesktopWindowZIndex,
  resetDesktopWindowUserTranslate,
  shiftDesktopWindowUserTranslate,
  selectDesktopWindowState,
  setDesktopWindowIsDragging,
  recalculateDesktopWindowSystemTranslate,
} from "../../../../store/desktops/desktops-slice";
import type { DOMRectJSON } from "../../../../store/desktops/desktops-types";
import { DesktopLayoutContext } from "../../../layout/desktop-layouts/base-desktop-layout";
import { SiteDesktopContainerContext } from "../../../layout/desktop-layouts/site-desktop-container";
import { MaybeTransitionState } from "../../../layout/transitions/transition-link-context";
import { ProgramTitlebar } from "./program-titlebar";
import {
  ProgramTitlebarButtonConfig,
  ProgramTitlebarPosition,
} from "./program-window-types";

interface StyledProgramWindowWrapperProps {
  // $dx, $dy are CSS math functions
  $dx: string;
  $dy: string;
  $isDragging: boolean;
  $isMinimized: boolean;
  $pageTransitionStatus: TransitionStatuses;
  $zIndex: number;
}
const StyledProgramWindowWrapper = styled.div.attrs<StyledProgramWindowWrapperProps>(
  (props) => {
    /* The transform property is overloaded - transform property on window currently used for:
      - Window dragging
      - Window open/close animations (page transitions)
      - Window snapping (for sticky windows)
    */
    let duration = props.theme.timings.pageTransitionSeconds;
    let systemTransform: string;
    // TODO: transfer opacity to the Desktop Layout level, so windows don't bleed through (only for page transitions)
    let opacity;
    // Default: support window snapping
    let transformTransition = "transform 0.1s ease,";
    // Override transform transition on page transition
    switch (props.$pageTransitionStatus) {
      case "entering":
        opacity = 0;
        transformTransition = `transform ${duration}s ease,`;
        systemTransform = "scale(1.05)";
        break;
      case "entered":
        opacity = 1;
        systemTransform = "scale(1)";
        break;
      case "exiting":
      case "exited":
        opacity = 0;
        transformTransition = `transform ${duration}s ease,`;
        systemTransform = "scale(0.975)";
        break;
    }
    if (props.$isMinimized) {
      opacity = 0;
      transformTransition = `transform ${duration}s ease,`;
      systemTransform = "scale(0.75)";
    }
    // Override transform transition while user is dragging window
    // Override opacity while user is dragging window
    // TODO: user option to disable the opacity
    if (props.$isDragging) {
      transformTransition = "";
      opacity = 0.9;
    }
    const opacityTransition = `opacity ${duration / 3}s linear`;
    const transition = `${transformTransition} ${opacityTransition}`;
    const zIndex = props.$isDragging
      ? Math.max(1000000, props.$zIndex)
      : props.$zIndex;

    return {
      "aria-hidden": props.$isMinimized,
      tabIndex: props.$isMinimized ? -1 : undefined,
      inert: props.$isMinimized ? "" : undefined,
      style: {
        transform: `translate(${props.$dx}, ${props.$dy}) ${systemTransform}`,
        opacity,
        transition,
        zIndex,
      },
    };
  }
)<StyledProgramWindowWrapperProps>`
  /* Hack: Fix the titlebar missing vertical padding in Safari with display: grid */
  /* https://stackoverflow.com/questions/55347359/why-is-css-grid-row-height-different-in-safari */
  display: grid;
  position: relative;
`;

// TODO: refactor this thing?
const stickyDelta = (x: number) => Math.sign(x) * Math.pow(Math.abs(x), 3 / 5);

const StyledProgramWindow = styled.section`
  /* TODO: look into some fancy theming with backdrop-filter blur, evaluate performance? */
  /* backdrop-filter: blur(4px); */

  display: grid;
  grid-template-areas:
    ".                                ${ProgramTitlebarPosition.TOP}"
    "${ProgramTitlebarPosition.LEFT}  content";
  grid-template-columns: min-content minmax(0, auto);

  /* Workaround for the issue with vertical titlebar margins */
  background-color: ${(props) => props.theme.colors.gui.windowBackground};
  border: 4px solid ${(props) => props.theme.colors.gui.windowBorder};
  color: ${(props) => props.theme.colors.text.base};
  box-shadow: ${(props) => props.theme.shadows.soft.heavy};
`;

const StyledProgramWindowBody = styled.div<{
  $applyContentPadding: boolean;
  $overflow: string;
}>`
  grid-area: content;
  padding: ${(props) => (props.$applyContentPadding ? "8px" : 0)};
  font-family: ${(props) => props.theme.fonts.family.gui};
  background-color: ${(props) => props.theme.colors.gui.windowBackground};
  overflow: ${(props) => props.$overflow};
`;

export type ProgramWindowProps = {
  windowId: string;
  children: React.ReactNode;
  contentOverflow?: string;
  applyContentPadding?: boolean;
  buttons?: ProgramTitlebarButtonConfig[];
  title: string;
  titlebarPosition?: ProgramTitlebarPosition;
  defaultGeometry?: {
    // These props $defaultTranslateX, $defaultTranslateY are CSS math functions
    defaultTranslateX?: number | string;
    defaultTranslateY?: number | string;
    // Defaults to 0. Higher = positioned on top of lower zIndexPriority, regardless of focus
    zIndexPriority?: number;
  };
  isMinimized?: boolean;
};

// TODO: rename this to DesktopWindow???
export const ProgramWindow = (props: ProgramWindowProps) => {
  const {
    children,
    windowId,
    applyContentPadding = true,
    contentOverflow = "visible",
    title,
    titlebarPosition = ProgramTitlebarPosition.TOP,
    buttons = [],
    defaultGeometry,
    isMinimized = false,
  } = props;

  const theme = useTheme();

  // This prevents system translate adjustments until layout is settled on page transition
  const [isWindowFresh, setIsWindowFresh] = React.useState(true);
  React.useEffect(() => {
    const interval = setTimeout(
      () => setIsWindowFresh(false),
      theme.timings.pageTransitionSeconds * 1000
    );
    return () => clearInterval(interval);
  }, [setIsWindowFresh]);

  let { defaultTranslateX = 0, defaultTranslateY = 0 } = defaultGeometry ?? {};
  if (typeof defaultTranslateX === "number") {
    defaultTranslateX = `${defaultTranslateX}px`;
  }
  if (typeof defaultTranslateY === "number") {
    defaultTranslateY = `${defaultTranslateY}px`;
  }

  const { isDesktopResizing, useWindowOutOfBoundsObserver } = React.useContext(
    SiteDesktopContainerContext
  );

  const { desktopId, canWindowsRearrange, isBoundsCheckRelativeToViewport } =
    React.useContext(DesktopLayoutContext);

  const dispatch = useAppDispatch();
  const zIndex = useAppSelector((state: RootState) =>
    selectDesktopWindowZIndex(state, desktopId, windowId)
  );

  // TODO: be able to update some fields separately
  React.useEffect(() => {
    const { zIndexPriority = 0 } = defaultGeometry ?? {};
    dispatch(
      initOrUpdateDesktopWindow({
        desktopId,
        windowId,
        zIndexPriority,
      })
    );
  }, [desktopId, windowId, defaultGeometry]);

  const { isDragging = false } =
    useAppSelector((state: RootState) =>
      selectDesktopWindowState(state, windowId)
    ) ?? {};

  const { translateX, translateY } = useAppSelector((state: RootState) =>
    selectDesktopWindowTranslate(state, windowId)
  );

  const [
    isVisibleNotMinimized,
    setIsVisibleNotMinimizedDebounced,
    setIsVisibleNotMinimizedImmediate,
  ] = useDebounce(true, theme.timings.pageTransitionSeconds * 1000);

  React.useEffect(() => {
    if (isMinimized) {
      setIsVisibleNotMinimizedDebounced(false);
      setIsVisibleNotMinimizedImmediate(false);
    } else {
      setIsVisibleNotMinimizedDebounced(true);
    }
  }, [
    isMinimized,
    setIsVisibleNotMinimizedDebounced,
    setIsVisibleNotMinimizedImmediate,
  ]);

  const isOutOfBoundsObserverActive =
    !isWindowFresh &&
    canWindowsRearrange &&
    !isDesktopResizing &&
    !isDragging &&
    isVisibleNotMinimized;

  // TODO: For efficiency, let's figure out if isOutOfBounds is still necessary (if not, then entire resizeobserver is redundant)
  const {
    ref: programWindowRef,
    // TODO: any situation where I actually would still need to use this? Can't solely use this because I want to snap windows back to edge on desktop expand.
    isOutOfBounds,
    entry,
  } = useWindowOutOfBoundsObserver({
    isActive: isOutOfBoundsObserverActive,
    isBoundsCheckRelativeToViewport,
  });

  React.useEffect(() => {
    if (!entry) {
      return;
    }
    const { boundingClientRect: win, rootBounds: desk } = entry;
    if (!desk) {
      return;
    }
    dispatch(
      recalculateDesktopWindowSystemTranslate({
        windowId,
        desk: serializeDomRect(desk),
        win: serializeDomRect(win),
      })
    );
  }, [entry, dispatch, recalculateDesktopWindowSystemTranslate, windowId]);

  const raiseWindowCallback = React.useCallback(() => {
    dispatch(bringWindowToFront({ windowId }));
  }, [dispatch, bringWindowToFront, windowId]);

  const onDragEvent = React.useCallback(
    ({ dx, dy }: { dx: number; dy: number }) => {
      dispatch(shiftDesktopWindowUserTranslate({ windowId, dx, dy }));
    },
    [dispatch, shiftDesktopWindowUserTranslate, windowId]
  );

  const onDragStatusChange = React.useCallback(
    (newIsDragging: boolean) => {
      dispatch(
        setDesktopWindowIsDragging({ windowId, isDragging: newIsDragging })
      );
      // For sticky windows, reset the _user_ translation after drag release
      if (!canWindowsRearrange && !newIsDragging) {
        dispatch(resetDesktopWindowUserTranslate({ windowId }));
      }
    },
    [
      dispatch,
      setDesktopWindowIsDragging,
      canWindowsRearrange,
      resetDesktopWindowUserTranslate,
      bringWindowToFront,
      windowId,
    ]
  );

  // TODO: restore all user window transforms on breakpoint change?
  return (
    <MaybeTransitionState>
      {({ transitionStatus }) => (
        <StyledProgramWindowWrapper
          onMouseDown={raiseWindowCallback}
          onTouchStart={raiseWindowCallback}
          $isDragging={isDragging}
          // TODO: change this logic to track sticky-dragging separately from rearrange-dragging (so that we can toggle canWindowsRearrange)
          $dx={`calc(${defaultTranslateX} + ${
            canWindowsRearrange ? translateX : stickyDelta(translateX)
          }px)`}
          $dy={`calc(${defaultTranslateY} + ${
            canWindowsRearrange ? translateY : stickyDelta(translateY)
          }px)`}
          $isMinimized={isMinimized}
          $pageTransitionStatus={transitionStatus}
          $zIndex={zIndex}
        >
          <StyledProgramWindow ref={programWindowRef}>
            <ProgramTitlebar
              title={title}
              position={titlebarPosition}
              buttons={buttons}
              onDragEvent={onDragEvent}
              onDragStatusChange={onDragStatusChange}
              raiseWindowCallback={raiseWindowCallback}
            />
            <StyledProgramWindowBody
              $applyContentPadding={applyContentPadding}
              $overflow={contentOverflow}
            >
              {children}
            </StyledProgramWindowBody>
          </StyledProgramWindow>
        </StyledProgramWindowWrapper>
      )}
    </MaybeTransitionState>
  );
};

export const ProgramWindowPadding = styled.div`
  padding: ${(props) => props.theme.spacings.panels.padding}px;
`;

const serializeDomRect = (r: DOMRect): DOMRectJSON => {
  return r.toJSON();
};
